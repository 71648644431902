import axios from "axios";
import authHeader from "./auth-header";

const SITE_API_URL = process.env.REACT_APP_SITE_API_URL

export const createSiteVideo = (video_id,template) =>{
    const data = {}
    return axios.post(SITE_API_URL + `create-video?videoId=${video_id}&template=${template}`, data, {headers: authHeader()});
};

export const createSiteFromTemplate = (domain,subdomain,template) => {
    const data = {};
    return axios.post(SITE_API_URL + `template/edit?domain=${domain}&subdomain=${subdomain}&template=${template}`, data, {headers: authHeader()});
};

export const getSitesTemplates = async () => {
    return await axios.get(SITE_API_URL + `getTemplates`, {headers: authHeader()});
};

export const getTemplateData = async (template) => {
    return await axios.get(SITE_API_URL + `getTemplateData?template=${template}`, {headers: authHeader()});
};

export const saveSiteTemplate = async (from, to) => {
    const data = {};
    return await axios.post(SITE_API_URL + `saveTemplate?from=${from}&to=${to}`, data, {headers: authHeader()});
};
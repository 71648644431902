import axios from "axios";
import authHeader from "./auth-header";

const DOMAINS_API_URL = process.env.REACT_APP_DOMAINS_API_URL

const getDomains = () => {
    return axios.get(DOMAINS_API_URL + "domains", {headers: authHeader()});
};

const createSubDomain = (domain, subdomain) => {
    var data = {}
    return axios.post(DOMAINS_API_URL + `create-subdomain?domain=${domain}&subdomain=${subdomain}`, data, {headers: authHeader()});
};

const enableHttps = (domain, subdomain) => {
    var data = {}
    return axios.post(DOMAINS_API_URL + `https/enable?domain=${domain}&subdomain=${subdomain}`, data, {headers: authHeader()});
};

const getSites = () =>{
    return axios.get(DOMAINS_API_URL + "siteslist", {headers: authHeader()});
};

const getSitesTemplates = () =>{
    return axios.get(DOMAINS_API_URL + "templates/sites/list", {headers: authHeader()});
};

export default {
    createSubDomain,
    getDomains,
    getSites,
    getSitesTemplates,
    enableHttps,
};
import {
    RESET_STATE,
    CREATE_COMMENT,
    CREATE_PIXEL,
    CREATE_UTMS,
    CREATE_VIDEO,
    SELECT_TEMPLATE_FAIL,
    SELECT_TEMPLATE_SUCCESS,
    CREATE_CAMPAING_SUCCESS,
    CREATE_CAMPAING_FAIL,
    SAVE_TEMPLATE_SUCCESS,
    SAVE_TEMPLATE_FAIL
} from "../actions/types";

const initialState = {
    StatusCode: 0,
    TemplateSelected: false,
    videoId: '',
    isSiteCreated: false,
    error: false,
};

export default function products(state = initialState, action) {
    const {type, payload} = action;

    switch (type) {
        case CREATE_PIXEL:
            return {
                ...state,
                pixel: payload.pixel,
            };

        case CREATE_COMMENT:
            return {
                ...state,
                comment: payload.comment,
            };

        case CREATE_VIDEO:
            return {
                ...state,
                videoId: payload.videoId,
            };

        case CREATE_UTMS:
            return {
                ...state,
                source: payload.source,
                medium: payload.medium,
                term: payload.term,
                content: payload.content,
                campaign: payload.campaign,
            };

        case SELECT_TEMPLATE_SUCCESS:
            return {
                ...state,
                TemplateSelected: true,
                template: payload.template,
                tempProductId: payload.tempProductId,
                tempProductPrice: payload.tempProductPrice,
                tempProductCount: payload.tempProductCount,
                tempProductComment: payload.tempProductComment,
                tempProductCategory: payload.tempProductCategory,
                tempVideoId: payload.tempVideoId
            };

        case SELECT_TEMPLATE_FAIL:
            return {
                ...state,
                error: true,
            };

        case CREATE_CAMPAING_SUCCESS:
            return {
                ...state,
                isSiteCreated: true,
                error: false
            };

        case CREATE_CAMPAING_FAIL:
            return {
                ...state,
                error: true,
                message: payload.message
            };

        case SAVE_TEMPLATE_SUCCESS:
            return {
                ...state,
                isTemplateSaved: true,
            };

        case SAVE_TEMPLATE_FAIL:
            return {
                ...state,
                error: true,
            };

        case RESET_STATE:
            return initialState;

        default:
            return state;
    }
}

import React, {useEffect,useState} from 'react'
import {TextField} from "@mui/material";

import {useDispatch, useSelector} from "react-redux";
import Grid from "@mui/material/Grid";
import {CREATE_UTMS} from "../../../actions/types";
import {isValidUtm} from "../../../helpers/Validators"

export default function CreateUTMS() {

    const {SelectedProductCategory, SelectedProductPrice} = useSelector(state => state.products);
    const {domain, subdomain} = useSelector(state => state.domains);
    const {user: currentUser} = useSelector((state) => state.auth);

    const [source] = useState(subdomain + "_" + domain + "_" + SelectedProductPrice);
    const [medium, setMedium] = useState('ch');
    const [term] = useState(SelectedProductCategory);
    const [content] = useState(currentUser.username);
    const [campaign, setCampaign] = useState('fb');

    const dispatch = useDispatch();

    useEffect(() => {
        createUtms()
    }, [source,medium,term,content,campaign]);

    const createUtms = () => {
        dispatch({
            type: CREATE_UTMS,
            payload: {
                source: source,
                medium: medium,
                term: term,
                content:content,
                campaign:campaign
            }
        });
    }

    const handleChangemediun = (event) => {
        event.preventDefault();
        setMedium(event.target.value);
        createUtms()
    };

    const handleChangeCampaing = (event) => {
        event.preventDefault();
        setCampaign(event.target.value);
        createUtms()
    };

    return (
        <div>
            <Grid container
                  spacing={2}
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  sx={{marginTop: 2, marginBottom: 5}}
            >
                <Grid item xs={5}>
                    <TextField sx={{padding: '10px 0 5px', minWidth: 300}}
                               id="standard-basic"
                               label="utm source - Мітка компанії"
                               defaultValue={subdomain + "_" + domain + "_" + SelectedProductPrice}
                               disabled={true}
                               variant="standard"/>
                </Grid>
                <Grid item xs={5}>
                    <TextField sx={{padding: '10px 0 5px', minWidth: 300}}
                               id="standard-basic"
                               label="utm medium - Країна виробник"
                               defaultValue={'ch'}
                               error={!isValidUtm(medium)}
                               helperText={!isValidUtm(medium)?"Заборонені символи у мітці!":""}
                               variant="standard" onChange={handleChangemediun}/>
                </Grid>
                <Grid item xs={5}>
                    <TextField sx={{padding: '10px 0 5px', minWidth: 300}}
                               id="standard-basic"
                               label="utm term - Продукт"
                               disabled={true}
                               defaultValue={SelectedProductCategory}
                               variant="standard" onChange={handleChangemediun}/>
                </Grid>
                <Grid item xs={5}>
                    <TextField sx={{padding: '10px 0 5px', minWidth: 300}}
                               id="standard-basic"
                               disabled
                               label="utm content - Баєр компанії"
                               defaultValue={currentUser.username}
                               variant="standard"/>
                </Grid>
                <Grid item xs={5}>
                    <TextField sx={{padding: '10px 0 5px', minWidth: 300}}
                               required
                               id="standard-basic"
                               label="utm campaign - Сервіс компанії"
                               defaultValue={'fb'}
                               error={!isValidUtm(campaign)}
                               helperText={!isValidUtm(campaign)?"Заборонені символи у мітці!":""}
                               variant="standard"
                               onChange={handleChangeCampaing}/>
                </Grid>
            </Grid>
        </div>
    );
}
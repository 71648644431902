import Typography from "@mui/material/Typography";
import {TextField} from "@mui/material";
import {isValidComment, isValidNumber} from "../../helpers/Validators";
import React, {useEffect, useState} from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import {DataGrid, GridCellEditStopReasons, GridColumns, useGridApiRef} from "@mui/x-data-grid";
import {darken, lighten} from "@mui/material/styles";
import Button from "@mui/material/Button";
import {saveSiteTemplate} from "../../services/site.service";
import CampaingService from "../../services/сampaings.service";
import {useSnackbar} from "notistack";
import {useSelector} from "react-redux";
import CloseIcon from '@mui/icons-material/Close';

const getHoverBackgroundColor = (color: string, mode: string) =>
    mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);

export default function CompanyMenu(props) {

    const {enqueueSnackbar} = useSnackbar();

    const [isSaved, setIsSaved] = useState(true);

    const [price, setPrice] = useState(false);

    const [videoID, setVideoID] = useState(false);

    const [count, setCount] = useState(false);

    const [facebookPixelId, setFacebookPixelId] = useState(false);

    const [comment, setComment] = useState(false);

    const {user: currentUser} = useSelector((state) => state.auth);

    const saveButtonText = isSaved ? "Зберегти шаблон" : "Зачейкайте , йде збереження..."

    const isEdited = () =>{
       return !(price || videoID || count || facebookPixelId || comment)
    }

    const handleChangeComment = (event) => {
        event.preventDefault();
        const inputValue = event.target.value;
        const formattedComment = inputValue.split('\n').map(line => line.trimEnd() + '\n').join('');
        setComment(formattedComment);
    }

    const closeMenu = (event) => {
        event.preventDefault()
        props.setOpenCampaingMenu(false)
        setPrice(false)
        setCount(false)
        setFacebookPixelId(false)
        setVideoID(false)
        setComment(false)
    }

    const columns: GridColumns = [
        {
            field: 'id',
            headerName: "",
            headerAlign: 'center',
            align: 'left',
            editable: false,
            sortable: false,
        },
        {
            field: 'columnValues',
            headerName: "ID",
            headerAlign: 'center',
            align: 'center',
            editable: true,
            sortable: false,
            flex: 1
        },
    ];

    const notEditableColumns: GridColumns = [
        {
            field: 'id',
            headerName: "",
            headerAlign: 'center',
            align: 'left',
            editable: false,
            sortable: false,
        },
        {
            field: 'columnValues',
            headerName: "ID",
            headerAlign: 'center',
            align: 'center',
            editable: true,
            sortable: false,
            flex: 1
        },
    ];

    const baseParam = [
        {
            id: 'Cтворив',
            columnValues: props.campaing.username
        },
        {
            id: 'Сайт',
            columnValues: props.campaing.subdomain + '.' + props.campaing.domain
        },
        {
            id: 'Шаблон',
            columnValues: props.campaing.template
        },
        {
            id: 'Pixel ID',
            columnValues: facebookPixelId ? facebookPixelId : props.campaing.facebookPixelId
        },
        {
            id: 'Video ID',
            columnValues: videoID ? videoID : props.campaing.youTubeVideoId
        },
    ]

    const productParam = [
        {
            id: 'ID',
            columnValues: props.campaing.product.productId
        },
        {
            id: 'Категорія',
            columnValues: props.campaing.product.productCategory
        },
        {
            id: 'Ціна',
            columnValues: price? price : props.campaing.product.productPrice
        },
        {
            id: 'Кількість',
            columnValues: count? count : props.campaing.product.productCount
        },
        {
            id: ' ',
            columnValues: ' '
        },
    ]

    const UtmsParam = [
        {
            id: 'Source',
            columnValues: props.campaing.utms.source
        },
        {
            id: 'Medium',
            columnValues: props.campaing.utms.medium
        },
        {
            id: 'Term',
            columnValues: props.campaing.utms.term
        },
        {
            id: 'Сontent',
            columnValues: props.campaing.utms.content
        },
        {
            id: 'Сampaign',
            columnValues: props.campaing.utms.campaign
        },
    ]

    const handleProcessRowUpdateError = React.useCallback((error) => {
        enqueueSnackbar(error.message, {variant: "error"});
    }, []);

    const useFakeMutation =  () => {
        return React.useCallback(
            (data) =>
                new Promise((resolve, reject) =>
                    setTimeout(() => {
                        switch (data.id) {
                            case 'Pixel ID':
                                if (!isValidNumber(data.columnValues)) {
                                    reject(new Error("Заборонені символи"));
                                    break
                                }
                                setFacebookPixelId(data.columnValues)
                                break
                            case 'Video ID':
                                if (!isValidComment(data.columnValues)) {
                                    reject(new Error("Заборонені символи"));
                                    break
                                }
                                setVideoID(data.columnValues)
                                break
                            case 'Ціна':
                                if (!isValidNumber(data.columnValues)) {
                                    reject(new Error("Заборонені символи"));
                                    break
                                }
                                setPrice(data.columnValues)
                                break
                            case 'Кількість':
                                if (!isValidNumber(data.columnValues)) {
                                    reject(new Error("Заборонені символи"));
                                    break
                                }
                                setCount(data.columnValues)
                                break
                        }
                            resolve({ ...data});
                    }, 200),
                ),
            [],
        );
    };

    const mutateRow = useFakeMutation();

    const processRowUpdate = React.useCallback(
        async (newRow) => {

            const response = await mutateRow(newRow);

            return response;
        },
        [],
    );

    const saveCampaing = async (event) => {
        event.preventDefault();

        setIsSaved(false)
        const urltemplate = props.campaing.subdomain + '.' + props.campaing.domain

        const payload = {
            "id": props.campaing.id,
            "username": currentUser.username,
            "domain": props.campaing.domain,
            "subdomain": props.campaing.subdomain,
            "template": props.campaing.subdomain + "." + props.campaing.domain,
            "comment": comment ? comment : props.campaing.comment,
            "facebookPixelId": facebookPixelId ? facebookPixelId : props.campaing.facebookPixelId,
            "youTubeVideoId": videoID  ? videoID : props.campaing.youTubeVideoId,
            "fb_account": {
                "accountName": props.campaing.fb_account.accountName,
                "accountLogin": props.campaing.fb_account.accountLogin,
                "accountPassword": props.campaing.fb_account.accountPassword,
                "accountComment": props.campaing.fb_account.accountComment,
                "card": props.campaing.fb_account.card
            },
            "initialBudget": props.campaing.initialBudget,
            "product": {
                "id": props.campaing.product.id,
                "productId": parseInt(props.campaing.product.productId),
                "productCategory": props.campaing.product.productCategory,
                "productPrice": parseInt(price ? price : props.campaing.product.productPrice),
                "productCount": parseInt(count ? count : props.campaing.product.productCount)
            },
            "utms": {
                "source": (props.campaing.subdomain + "_" + props.campaing.domain + "_") + (price ? price : props.campaing.product.productPrice),
                "medium": props.campaing.utms.medium,
                "term": props.campaing.utms.term,
                "content": props.campaing.utms.content,
                "campaign": props.campaing.utms.campaign
            }
        }

        console.log(payload)

        await saveSiteTemplate(urltemplate, urltemplate).then(
            (response) => {
                const parsed_response = Object.values(response)[0];

                if (parsed_response.startsWith('Шаблон збережено')) {

                    CampaingService.saveCampaigChange(payload).then(
                        (response) => {
                            if (response.data.success[0] === 'Зміни збережені') {
                                setIsSaved(true)
                                props.loadTableData(0, 10)
                                props.setOpenCampaingMenu(false)
                                enqueueSnackbar('Зміни збережено', {variant: "success"});
                                return
                            }
                            if (response.data.errors[0] === 'Зміни не збережено') {
                                setIsSaved(true)
                                enqueueSnackbar('Невдалося зберегти зміні. Спробуйте ще раз.', {variant: "error"});

                            }
                        }
                    ).catch((error) => {
                        setIsSaved(true)
                        if (error.response.data.errors) {
                            enqueueSnackbar(error.response.data.errors[0], {variant: "error"});
                            return
                        }
                        enqueueSnackbar('Невідома помилка. Зверніться до адміністратора.', {variant: "error"});
                    });

                }
            },
            (error) => {
                setIsSaved(true)
                if (error.response.data.errors) {
                    enqueueSnackbar(error.response.data.errors[0], {variant: "error"});
                    return
                }
                enqueueSnackbar('Невідома помилка. Зверніться до адміністратора.', {variant: "error"});
            })
    }

    return (
        <Modal
            open={props.open}
            onClose={closeMenu}
        >
            <Box
                sx={{
                    // position: 'absolute',
                    // top: '50%',
                    // left: '50%',
                    // transform: 'translate(-50%, -50%)',
                    mt: '10%',
                    width: '70%',
                    height: '60%',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    wordWrap: 'break-word',
                    backgroundColor: "rgba(52,58,64,1)",
                    "& .MuiDataGrid-virtualScrollerRenderZone": {
                        "& .MuiDataGrid-row": {
                            "&:nth-of-type(1n):not(.Mui-selected)": {backgroundColor: "rgba(255,255,255, .5)"},
                            "&:nth-of-type(2n):not(.Mui-selected)": {backgroundColor: "rgba(224, 224, 224, .5)"},
                            '&:hover:not(.Mui-selected)': {
                                bgcolor: (theme) =>
                                    getHoverBackgroundColor("rgba(101,99,99,0.5)", theme.palette.mode),
                            },
                        },
                    },
                    "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: "rgba(52,58,64,1)",
                        color: "rgba(255,255,255,1)",
                        fontSize: 16
                    },
                    "& .MuiDataGrid-footerContainer": {
                        backgroundColor: "rgba(52,58,64,1)",
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        "& .MuiTablePagination-selectLabel": {
                            paddingTop: 1.4
                        },
                        "& .MuiTablePagination-displayedRows": {
                            paddingTop: 1.4
                        },
                        "& .MuiDataGrid-selectedRowCount": {
                            color: 'white',
                        }
                    },
                    "& .MuiTablePagination-toolbar": {
                        color: 'white',
                    },
                }}
            >
                <Box
                    display="flex"
                    justifyContent="left"
                    alignItems="center"
                >
                    <Box
                        display="flex"
                        justifyContent="left"
                        sx={{width: '100%'}}
                    >
                        <Typography variant="h6" p={2} sx={{color: 'white'}}>
                            Компанія #{props.campaing.id} від {props.campaing.date}
                        </Typography>
                    </Box>

                    <Box
                        display="flex"
                        justifyContent="right"
                        sx={{pr: 2, width: '100%'}}
                    >
                        <CloseIcon
                            onClick={closeMenu}
                            sx={{
                                color: 'white',
                                "&:hover": {
                                    color: "#9d9d9d"
                                }
                            }} fontSize="large" color="action"/>
                    </Box>
                </Box>

                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                        pt: 2,
                        width: '100%',
                        backgroundColor: "white"
                    }}
                >
                    <Typography align='center' variant="h6" sx={{width: '100%'}}>
                        Основні
                    </Typography>
                    <Typography align='center' variant="h6" sx={{width: '100%'}}>
                        Продукт
                    </Typography>
                    <Typography align='center' variant="h6" sx={{width: '100%'}}>
                        Мітки
                    </Typography>
                </Box>
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"

                    sx={{
                        width: '100%',
                        backgroundColor: "white",
                    }}
                >
                    {baseParam[0]?
                        <DataGrid
                            headerHeight={0}
                            hideFooter={true}
                            loading={false}
                            rows={baseParam}
                            columns={columns}
                            rowCount={1}
                            autoHeight={true}
                            showCellRightBorder={true}
                            disableColumnFilter
                            disableColumnMenu
                            disableColumnSelector
                            sx={{m: 2}}
                            experimentalFeatures={{ newEditingApi: true }}
                            onProcessRowUpdateError={handleProcessRowUpdateError}
                            processRowUpdate={processRowUpdate}
                            isCellEditable={(params) => params.row.id === 'Pixel ID' || params.row.id === 'Video ID'}
                        /> : <div></div>}
                    <DataGrid
                        headerHeight={0}
                        hideFooter={true}
                        loading={false}
                        rows={productParam}
                        columns={columns}
                        rowCount={1}
                        autoHeight={true}
                        showCellRightBorder={true}
                        disableColumnFilter
                        disableColumnMenu
                        disableColumnSelector
                        sx={{m: 2}}
                        experimentalFeatures={{ newEditingApi: true }}
                        onProcessRowUpdateError={handleProcessRowUpdateError}
                        processRowUpdate={processRowUpdate}
                        isCellEditable={(params) => params.row.id === 'Ціна' || params.row.id === 'Кількість'}
                    />
                    <DataGrid
                        headerHeight={0}
                        hideFooter={true}
                        loading={false}
                        rows={UtmsParam}
                        columns={notEditableColumns}
                        rowCount={1}
                        autoHeight={true}
                        showCellRightBorder={true}
                        disableColumnFilter
                        disableColumnMenu
                        disableColumnSelector
                        sx={{m: 2}}
                    />
                </Box>
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                        width: '100%',
                        height: '10%',
                        backgroundColor: "white",
                    }}
                >
                    <Typography variant="h6" sx={{mb: 2}}>
                        Коментарій
                    </Typography>
                </Box>
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                        width: '100%',
                        height: '20%',
                        backgroundColor: "white",
                    }}
                >
                    <TextField sx={{marginLeft: 3, padding: '10px 0 5px', minWidth: 500, mb: 3}}
                               id="outlined-multiline-static"
                               label=" "
                               defaultValue={comment ? comment : props.campaing.comment}
                               onChange={handleChangeComment}
                               required
                               error={!isValidComment(comment ? comment : props.campaing.comment)}
                               helperText={!isValidComment(comment ? comment : props.campaing.comment) ? "Заборонені символи у коментарі!" : ""}
                               multiline
                               rows={3}/>
                </Box>
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                        width: '100%',
                        height: '12%',
                        backgroundColor: "rgba(52,58,64,1)"
                    }}
                >
                    <Button variant="contained"
                            disabled={!isSaved || isEdited()}
                            onClick={saveCampaing}
                            sx={{
                                padding: '5px 5 5px',
                                marginBottom: 3,
                                marginTop: 3,
                                marginLeft: 1,
                                "&:disabled": {
                                    color: 'white'
                                }
                            }}
                    > {saveButtonText}
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
}
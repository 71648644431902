import React, {useState, useRef} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Navigate} from 'react-router-dom';

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import AccountCircle from '@mui/icons-material/AccountCircle';
import KeyIcon from '@mui/icons-material/Key';
import {Card} from "@mui/material";
import CardContent from '@mui/material/CardContent';

import {login} from "../../actions/auth";
import Grid from "@mui/material/Grid";

const Login = (props) => {
    const checkBtn = useRef();

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const {isLoggedIn} = useSelector(state => state.auth);
    const {message} = useSelector(state => state.message);

    const dispatch = useDispatch();

    const onChangeUsername = (e) => {
        const username = e.target.value;
        setUsername(username);
    };

    const onChangePassword = (e) => {
        const password = e.target.value;
        setPassword(password);
    };

    const handleLogin = (e) => {
        e.preventDefault();

        if (checkBtn.current.validity.valid) {
            dispatch(login(username, password))
                .then(() => {
                    props.history.push("/");
                    window.location.reload();
                })
                .catch(() => {
                });
        } else {
        }
    };

    if (isLoggedIn) {
        return <Navigate to="/"/>;
    }

    return (
        <div className="container">
            <div className="auth-container">
                <Card sx={{minWidth: 150}}>
                    <CardContent>
                        <Grid container
                              spacing={2}
                              direction="column"
                              justifyContent="center"
                              alignItems="center"
                              sx={{marginTop: 2,marginBottom: 5}}
                        >
                            <Grid item xs={5}>
                                <AccountCircle sx={{
                                    display: 'flex',
                                    color: 'action.active',
                                    mr: 1,
                                    my: 0.5,
                                    'marginLeft': 'auto',
                                    'marginRight': 'auto',
                                    width: '40%',
                                    height: '40%'
                                }}/>
                            </Grid>
                            <Grid item xs={5}>
                                <form onSubmit={handleLogin}>
                                    <Box sx={{
                                        display: 'flex',
                                        alignItems: 'flex-end',
                                        'marginLeft': 'auto',
                                        'marginRight': 'auto',
                                        margin: 2
                                    }}>
                                        <AccountCircle sx={{color: 'action.active', mr: 1, my: 0.5}}/>
                                        <TextField sx={{padding: '5px 0 5px'}} required id="input-with-sx" label="Логін"
                                                   variant="standard" type="text"
                                                   onChange={onChangeUsername}/>
                                    </Box>
                                    <Box sx={{
                                        display: 'flex',
                                        alignItems: 'flex-end',
                                        'marginLeft': 'auto',
                                        'marginRight': 'auto',
                                        margin: 2
                                    }}>
                                        <KeyIcon sx={{color: 'action.active', mr: 1, my: 0.5}}/>
                                        <TextField sx={{padding: '5px 0 5px'}} required id="input-with-sx 2" label="Пароль"
                                                   variant="standard" type="password"
                                                   onChange={onChangePassword}/>
                                    </Box>
                                    <Button sx={{
                                        display: 'flex',
                                        alignItems: 'flex-end',
                                        'marginTop': 4,
                                        'marginLeft': 'auto',
                                        'marginRight': 'auto',
                                        'width': '60%'
                                    }} ref={checkBtn} variant="contained" type="submit">Увійти</Button>
                                    {message && (
                                        <div className="form-group">
                                            <div className="alert alert-danger" role="alert">
                                                {message}
                                            </div>
                                        </div>
                                    )}
                                </form>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </div>
        </div>
    );
};

export default Login;

import axios from "axios";
import authHeader from "./auth-header";

const PRODUCT_API_URL = process.env.REACT_APP_PRODUCT_API_URL

const getCategories  = () => {
    return axios.get(PRODUCT_API_URL + "getCategories");
};

const getProducts = async () => {
    return await axios.get(PRODUCT_API_URL + "getProducts");
};

const getCargoDescription = () => {
    return axios.post(PRODUCT_API_URL + "getCargoDescription");
};

const createProduct = async (name, category_id, price, devdeskript, price_enter) => {
    var data = {}
    return await axios.post(PRODUCT_API_URL + `createProduct?name=${name}&category_id=${category_id}&price=${price}&price_enter=${price_enter}&devdeskript=${devdeskript}`, data, {headers: authHeader()});
};

const createProductWithType = async (name, type, category_id, price, devdeskript, price_enter) => {
    var data = {}
    return await axios.post(PRODUCT_API_URL + `createProductWithType?name=${name}&type=${type}&category_id=${category_id}&price=${price}&price_enter=${price_enter}&devdeskript=${devdeskript}`, data, {headers: authHeader()});
};

const createCategory = async (category) => {
    var data = {}
    return await axios.post(PRODUCT_API_URL + `createCategory?categoryName=${category}`, data, {headers: ""});
};

export default {
    getProducts,
    getCategories,
    createProduct,
    createProductWithType,
    createCategory,
    getCargoDescription,
};
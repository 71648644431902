import React, {useEffect, useState} from 'react'
import {TextField} from "@mui/material";
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';

import {useDispatch, useSelector} from "react-redux";
import {createCategory, createProduct} from "../../../actions/products";

import ProductService from "../../../services/products.service"
import Grid from "@mui/material/Grid";
import {CREATE_PRODUCT_SUCCESS} from "../../../actions/types";
import {isValidNumber} from "../../../helpers/Validators"

function uniqBy(a, key) {
    let seen = new Set();
    return a.filter(item => {
        let k = key(item);
        return seen.has(k) ? false : seen.add(k);
    });
}

export default function CreateProduct(props) {

    const {tempProductId, tempProductPrice, tempProductCount, tempProductCategory} = useSelector(state => state.sites);

    const isOfferNew = props.isTest
    const [category, setCategory] = useState(tempProductCategory);
    const [cargodescription, setCargoDescription] = useState([]);
    const [cargoref, setCargoRef] = useState([]);
    const [productID, setProductID] = useState(tempProductId);
    const [productName, setProductName] = useState([]);
    const [price, setPrice] = useState(tempProductPrice);
    const [priceenter, setPriceEnter] = useState([]);
    const [productList, setProductList] = useState([]);
    const [count, setCount] = useState(tempProductCount);

    const {StatusCodeProduct} = useSelector(state => state.products);

    const dispatch = useDispatch();

    const defaultProduct = {
        label: tempProductCategory,
        id: tempProductId
    }

    useEffect(() => {
        if(!isOfferNew) {
            setProductID(tempProductId)
            setPrice(tempProductPrice)
            setCategory(tempProductCategory)
            setCount(tempProductCount)
            dispatch({
                type: CREATE_PRODUCT_SUCCESS,
                payload: {
                    SelectedProductId: tempProductId,
                    SelectedProductCategory: tempProductCategory,
                    SelectedProductCount: tempProductCount,
                    SelectedProductPrice: tempProductPrice
                }
            });
        }
    }, []);

    useEffect(() => {
    }, [productID,productName,price,priceenter,count,category]);


    const loadCargoDescription = (event) => {
        event.preventDefault();
        const options = []
        ProductService.getCargoDescription()
            .then((response) => {
                Object.values(response.data.data).forEach(cat => {
                    let option = {
                        label: cat.Description,
                        id: cat.Ref
                    }
                    options.push(option)
                })
                setCargoDescription(options)
            })
            .catch(() => {
            });
    };

    const loadProductList = (event) => {
        event.preventDefault();
        const options = []
        ProductService.getProducts()
            .then((response) => {
                Object.values(response.data).forEach(product => {
                        let option = {
                            label: product.name.split(':')[0] + '',
                            id: product.id,
                        }
                        options.push(option)
                    }
                )
                setProductList(options.sort((a, b) => (a.label > b.label) ? 1 : -1))
            })
            .catch(() => {
            });
    };
    const handleChangeProduct = (event, newValue) => {
        event.preventDefault();
        console.log(newValue.id)
        setProductID(newValue.id);
        setCategory(newValue.label)

        dispatch({
            type: CREATE_PRODUCT_SUCCESS,
            payload: {
                SelectedProductId: newValue.id,
                SelectedProductCategory: newValue.label,
                SelectedProductCount: count,
                SelectedProductPrice: price,
            }
        });
    };

    const handleProduct = (event) => {
        event.preventDefault();
        setProductName(event.target.value);
        setCategory(event.target.value);
    };

    const handleChangePrice = (event) => {
        event.preventDefault();
        setPrice(event.target.value);
        if(!isOfferNew) {
            dispatch({
                type: CREATE_PRODUCT_SUCCESS,
                payload: {
                    SelectedProductId: productID,
                    SelectedProductCategory: category,
                    SelectedProductCount: count,
                    SelectedProductPrice: event.target.value
                }
            });
        }
    };

    const handleChangeCount = (event) => {
        event.preventDefault();
        setCount(event.target.value);
        dispatch({
            type: CREATE_PRODUCT_SUCCESS,
            payload: {
                SelectedProductId: productID,
                SelectedProductCategory: category,
                SelectedProductCount: event.target.value,
                SelectedProductPrice: price
            }
        });
    };

    const handleChangeCargoDescription = (event, newValue) => {
        event.preventDefault();
        setCargoRef(Object.values(newValue)[1]);
    };

    const handleChangePriceEnter = (event) => {
        event.preventDefault();
        setPriceEnter(event.target.value);
    };

    const createProd = async (event) => {
        event.preventDefault();
        try {
            await dispatch(createCategory(category));
            const categoriesResponse = await ProductService.getCategories();
            const categories = Object.values(categoriesResponse.data)[0];
            const selectedCategory = categories.find((cat) => cat.name === category);
            const categoryId = selectedCategory.id;
            const options = categories.reduce((acc, cat) => {
                acc.push({ label: cat.name, id: cat.id });
                cat.child.forEach((childCat) => {
                    acc.push({ label: childCat.name, id: childCat.id });
                });
                return acc;
            }, []);
            await dispatch(createProduct(productName, categoryId, price, cargoref, priceenter));
            const productsResponse = await ProductService.getProducts();
            const createdProduct = Object.values(productsResponse.data).find((product) => product.name.split(':')[0] === productName);
            if (createdProduct) {
                dispatch({
                    type: CREATE_PRODUCT_SUCCESS,
                    payload: {
                        SelectedProductId: createdProduct.id,
                        SelectedProductCategory: category,
                        SelectedProductCount: 1,
                        SelectedProductPrice: price,
                    },
                });
            }
        } catch (error) {
        }
    };

    const StatusInfo = () => {
        if (StatusCodeProduct !== 0) {
            if (StatusCodeProduct === 1) {
                return <Alert sx={{marginTop: 2, marginBottom: 3}} variant="filled" severity="success">
                    Категорію створено. Створюється продукт...
                </Alert>
            }
            if (StatusCodeProduct === 2) {
                return <Alert sx={{marginTop: 2, marginBottom: 3}} variant="filled" severity="error">
                    Невдалося створити категорію. Категорія вже існує чи відсутній зв'язок.
                </Alert>
            }
            if (StatusCodeProduct === 3&&isOfferNew) {
                return <Alert sx={{marginTop: 2, marginBottom: 3}} variant="filled" severity="success">
                    Товар успішно створено
                </Alert>
            }
            if (StatusCodeProduct === 4) {
                return <Alert sx={{marginTop: 2, marginBottom: 3}} variant="filled" severity="error">
                    Невдалося створити товар. Товар вже існує чи відсутній зв'язок.
                </Alert>
            }
        }

    };

    const getProductMenu = () => {
        if (isOfferNew) {
            return (
                <div>
                    {StatusInfo()}
                    <Grid container
                          spacing={2}
                          direction="column"
                          justifyContent="center"
                          alignItems="center"
                          sx={{marginBottom: 5}}
                    >
                        <Grid item xs={5}>
                            <TextField sx={{padding: '10px 0 5px', minWidth: 300}}
                                       id="standard-basic"
                                       label="Назва товару"
                                       variant="standard"
                                       onChange={handleProduct}/>
                        </Grid>
                        <Grid item xs={5}>
                            <TextField sx={{padding: '10px 0 5px', minWidth: 300}}
                                       id="standard-basic"
                                       label="Ціна"
                                       variant="standard"
                                       onChange={handleChangePrice}/>
                        </Grid>
                        <Grid item xs={5}>
                            <Autocomplete
                                loading
                                loadingText={'Завантаження...'}
                                disablePortal
                                id="combo-box-domains"
                                onOpen={loadCargoDescription}
                                options={cargodescription}
                                sx={{width: 300}}
                                onChange={handleChangeCargoDescription}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                renderInput={(params) => <TextField {...params} sx={{padding: '5px 0 5px'}}
                                                                    variant="standard"
                                                                    label="Опис вантажу"/>}
                            />
                        </Grid>
                        <Grid item xs={5}>
                            <TextField sx={{padding: '10px 0 5px', minWidth: 300}} id="standard-basic"
                                       label="Ціна закупки"
                                       variant="standard" onChange={handleChangePriceEnter}/>
                        </Grid>
                        <Grid item xs={5}>
                            <Button variant="contained"
                                    onClick={createProd}
                                    sx={{padding: '5px 5 5px', marginBottom: 3, marginTop: 3}}
                            > Створити товар
                            </Button>
                        </Grid>
                    </Grid>
                </div>
            );
        } else {
            return (
                <div>
                    {StatusInfo()}
                    <Grid container
                          spacing={2}
                          direction="column"
                          justifyContent="center"
                          alignItems="center"
                          sx={{marginBottom: 5}}
                    >
                        <Grid item xs={5}>
                            <Autocomplete
                                loading
                                loadingText={'Завантаження...'}
                                disablePortal
                                id="combo-box-domains"
                                onOpen={loadProductList}
                                options={uniqBy(productList, product => product.label)}
                                sx={{width: 300}}
                                onChange={handleChangeProduct}
                                defaultValue={defaultProduct}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                renderInput={(params) => <TextField {...params} sx={{padding: '5px 0 5px'}}
                                                                    variant="standard"
                                                                    label="Оберіть товар"/>}
                            />
                        </Grid>
                        <Grid item xs={5}>
                            <TextField sx={{padding: '10px 0 5px', width: 300}}
                                       required
                                       id="standard-basic"
                                       label="Ціна товару"
                                       defaultValue={tempProductPrice}
                                       variant="standard"
                                       onChange={handleChangePrice}
                                       error={!isValidNumber(price)}
                                       helperText={!isValidNumber(price)?"Заборонені символи!":""}/>
                        </Grid>
                        <Grid item xs={5}>
                            <TextField sx={{padding: '10px 0 5px', width: 300}}
                                       required
                                       id="standard-basic"
                                       label="Кількість товару"
                                       defaultValue={tempProductCount}
                                       variant="standard"
                                       onChange={handleChangeCount}
                                       error={!isValidNumber(count)}
                                       helperText={!isValidNumber(count)?"Заборонені символи!":""}/>
                        </Grid>
                    </Grid>
                </div>
            );
        }
    }

    return (
        <div>
            {getProductMenu()}
        </div>
    );
}
import React from "react";
import {Navigate} from 'react-router-dom';
import {useSelector} from "react-redux";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';

const Profile = () => {
    const {user: currentUser} = useSelector((state) => state.auth);

    if (!currentUser) {
        return <Navigate to="/login"/>;
    }

    return (
        <Box sx={{maxWidth: 600, marginLeft: 'auto', marginRight: 'auto', padding: 4}}>
            <Paper elevation={3} sx={{padding: 4}}>
                <h3>
                    <strong>Користувач:</strong> {currentUser.username}
                </h3>

                <p>
                    <strong>Token:</strong> {currentUser.token.substring(0, 20)} ...{" "}
                    {currentUser.token.substr(currentUser.token.length - 20)}
                </p>
                <p>
                    <strong>Id:</strong> {currentUser.id}
                </p>
                <p>
                    <strong>Email:</strong> {currentUser.email}
                </p>
                <strong>Группи:</strong>
                <ul>
                    {currentUser.roles &&
                        currentUser.roles.map((role, index) => <li key={index}>{role}</li>)}
                </ul>
            </Paper>
        </Box>
    );
};

export default Profile;

import React, {useEffect} from 'react'
import {useSelector} from "react-redux";
import {Navigate} from 'react-router-dom';
import UserService from "../../services/user.service";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import {styled} from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import ButtonBase from '@mui/material/ButtonBase';
import poster1 from '../../assets/images/posters/poster1.webp';
import poster2 from '../../assets/images/posters/poster2.webp';
import poster3 from '../../assets/images/posters/poster3.webp';
import EventBus from "../../common/EventBus";
import CardText from "./CardText";

const Img = styled('img')({
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
});

const Dashboard = () => {
    const {isLoggedIn} = useSelector((state) => state.auth);

    const getBoard = () => {
        UserService.getUserBoard().then(() => {

        }).catch((error) => {
            if (error.response) {
                EventBus.dispatch("logout");
            }
        })
    }

    useEffect(() => {
        getBoard()
    }, []);

    if (!isLoggedIn) {
        return <Navigate to="/login"/>;
    }

    return (
        <div>
            <Box sx={{maxWidth: 1024, marginLeft: 'auto', marginRight: 'auto', padding: 4}}>

                <Paper elevation={3} sx={{marginTop: 10}}>
                    <Grid container spacing={0}>
                        <Grid item xs={0} sm container>
                            <Grid item xs container direction="column" spacing={1} sx={{padding: 4}}>
                                <Grid item xs>
                                    <Typography sx={{fontWeight: 'bold',marginBottom: 2}} variant="h6" component="div" gutterBottom>Оновлення 1.8</Typography>
                                    <CardText color="red" subtext="new" text='Оновлене меню компанії компанії'/>
                                    <CardText color="red" subtext="new" text='Швидкий перезапуск компанії з меню компаній'/>
                                    <CardText color="red" subtext="new" text='Окремі вкладки Нова та Тестова компанія'/>
                                    <CardText color="red" subtext="new" text='Автоматичне створення доменів'/>
                                    <CardText color="red" subtext="new" text='Меню збереження компанії у шаблон'/>
                                    <CardText color="red" subtext="new" text='Автоматична зміна ціни у шаблоні'/>
                                    <CardText color="red" subtext="new" text='Масове створення товарів'/>
                                    <CardText color="blue" subtext="bugs" text='fixs...'/>
                                </Grid>
                                <Grid item>
                                    <Typography sx={{fontWeight: 'bold', cursor: 'pointer'}} variant="body2">
                                        Ваш системний адміністратор Niofatum
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <ButtonBase sx={{width: 'auto',mt:6, height: 290}}>
                                <Img alt="baner" src={poster3}/>
                            </ButtonBase>
                        </Grid>
                    </Grid>
                </Paper>

                <Paper elevation={3} sx={{marginTop: 10}}>
                    <Grid container spacing={0}>
                        <Grid item xs={0} sm container>
                            <Grid item xs container direction="column" spacing={1} sx={{padding: 4}}>
                                <Grid item xs>
                                    <Typography sx={{fontWeight: 'bold',marginBottom: 2}} variant="h6" component="div" gutterBottom>Оновлення
                                        1.7</Typography>
                                    <Typography display="block"> - new Валідація параметрів при створенні
                                        компанії</Typography>
                                    <Typography display="block"> - new Зміна ціни компанії через меню</Typography>
                                    <Typography display="block"> - new Зміна коментарія компанії через меню</Typography>
                                    <Typography display="block"> - new Новий Дашбоард</Typography>
                                    <Typography display="block"> - bugs fixs...</Typography>
                                </Grid>
                                <Grid item>
                                    <Typography sx={{fontWeight: 'bold', cursor: 'pointer'}} variant="body2">
                                        Ваш системний адміністратор Niofatum
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <ButtonBase sx={{width: 'auto', height: 260}}>
                                <Img alt="baner" src={poster2}/>
                            </ButtonBase>
                        </Grid>
                    </Grid>
                </Paper>

                <Paper elevation={3} sx={{marginTop: 10}}>
                    <Grid container spacing={0}>
                        <Grid item xs={0} sm container>
                            <Grid item xs container direction="column" spacing={1} sx={{padding: 4}}>
                                <Grid item xs>
                                    <Typography sx={{fontWeight: 'bold',marginBottom: 2}} variant="h6" component="div" gutterBottom>Оновлення 1.6</Typography>
                                    <Typography display="block"> - new Меню компанії</Typography>
                                    <Typography display="block"> - new Авто вмикання ssl</Typography>
                                    <Typography display="block"> - new Авто тег titile</Typography>
                                    <Typography display="block"> - new Кнопка для автоматичного тестування замовлень</Typography>
                                </Grid>
                                <Grid item>
                                    <Typography sx={{fontWeight: 'bold', cursor: 'pointer'}} variant="body2">
                                        Ваш системний адміністратор Niofatum
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <ButtonBase sx={{width: 'auto', height: 260}}>
                                <Img alt="baner" src={poster1}/>
                            </ButtonBase>
                        </Grid>
                    </Grid>
                </Paper>
            </Box>
        </div>
    );
};

export default Dashboard;
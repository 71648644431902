import axios from "axios";
import authHeader from "./auth-header";

const MAIL_API_URL = process.env.REACT_APP_MAIL_API_URL

const getGenaratedMail = () => {
    return axios.get(MAIL_API_URL + 'generate' , {headers: authHeader()});
}

const createMail = (data) => {
    return axios.post(MAIL_API_URL + 'create' ,data, {headers: header()});
}

const header = () => {
    const user = JSON.parse(localStorage.getItem("user"));

    if (user && user.token) {
        return {Authorization: "Bearer " + user.token,
            'Content-Type': 'application/json'
        };
    } else {
        return {};
    }
}

export default {
    getGenaratedMail,
    createMail,
};
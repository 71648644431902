import React, {useEffect, useState} from 'react'
import {TextField} from "@mui/material";

import {useDispatch, useSelector} from "react-redux";

import Grid from "@mui/material/Grid";
import {CREATE_VIDEO} from "../../../actions/types";

export default function CreateVideo() {

    const {tempVideoId} = useSelector(state => state.sites);

    const [videoId, setVideoId] = useState([tempVideoId]);

    const dispatch = useDispatch();

    useEffect(() => {
        createVIDEO()
    }, [videoId]);

    const createVIDEO = () => {
        dispatch({
            type: CREATE_VIDEO,
            payload: {
                videoId: videoId,
            }
        });
    }

    const handleChangeVideoId = (event) => {
        event.preventDefault()
        setVideoId(event.target.value)
        createVIDEO()
    };

    return (
        <div>
            <Grid container
                  spacing={2}
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  sx={{marginTop: 2, marginBottom: 5}}
            >
                <Grid item xs={5}>
                    <TextField sx={{padding: '10px 0 5px', marginRight: 3, minWidth: 300}} id="standard-basic"
                               label="ID Відео к товару"
                               variant="standard"
                               onChange={handleChangeVideoId}/>
                </Grid>
            </Grid>
        </div>
    );
}
import {NEW_OFFER, OLD_OFFER, RESET_STATE} from "../actions/types";

const initialState = {isOfferNew:false};

export default function offers(state = initialState, action) {
    const {type} = action;

    switch (type) {
        case NEW_OFFER:
            return {
                ...state,
                isOfferNew: true,
            };

        case OLD_OFFER:
            return {
                ...state,
                isOfferNew: false,
            };

        case RESET_STATE:
            return initialState;

        default:
            return state;
    }
}

import {
    CREATE_CATEGORY_SUCCESS,
    CREATE_CATEGORY_FAIL,
    CREATE_PRODUCT_SUCCESS,
    CREATE_PRODUCT_FAIL,
    RESET_STATE
} from "../actions/types";

const initialState = {StatusCodeProduct:0};

export default function products(state = initialState, action) {
    const {type,payload} = action;

    switch (type) {
        case CREATE_CATEGORY_SUCCESS:
            return {
                ...state,
                StatusCodeProduct: 1,
            };

        case CREATE_CATEGORY_FAIL:
            return {
                ...state,
                StatusCodeProduct: 2,
            };

        case CREATE_PRODUCT_SUCCESS:
            return {
                ...state,
                StatusCodeProduct: 3,
                SelectedProductId: payload.SelectedProductId,
                SelectedProductCategory: payload.SelectedProductCategory,
                SelectedProductCount: payload.SelectedProductCount,
                SelectedProductPrice: payload.SelectedProductPrice,
            };

        case CREATE_PRODUCT_FAIL:
            return {
                ...state,
                StatusCodeProduct: 4,
            };

        case RESET_STATE:
            return initialState;

        default:
            return state;
    }
}

export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";

export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";

export const CREATE_SUBDOMAIN_SUCCESS = "CREATE_SUBDOMAIN_SUCCESS";
export const CREATE_SUBDOMAIN_FAIL = "CREATE_SUBDOMAIN_FAIL";

export const CREATE_PRODUCT_SUCCESS = "CREATE_PRODUCT_SUCCESS";
export const CREATE_PRODUCT_FAIL = "CREATE_PRODUCT_FAIL";

export const GET_PRODUCT_SUCCESS = "GET_PRODUCT_SUCCESS";
export const GET_PRODUCT_FAIL = "GET_PRODUCT_FAIL";

export const CREATE_CATEGORY_SUCCESS = "CREATE_CATEGORY_SUCCESS";
export const CREATE_CATEGORY_FAIL = "CREATE_CATEGORY_FAIL";

export const GET_CATEGORY_SUCCESS = "GET_CATEGORY_SUCCESS";
export const GET_CATEGORY_FAIL = "GET_CATEGORY_FAIL";

export const CREATE_PIXEL = "CREATE_PIXEL";
export const CREATE_COMMENT = "CREATE_COMMENT";
export const CREATE_UTMS = "CREATE_UTMS";
export const CREATE_VIDEO = "CREATE_VIDEO";
export const CREATE_SHEETSDATA = "CREATE_SHEETSDATA";

export const CREATE_CAMPAING_SUCCESS = "CREATE_CAMPAING_SUCCESS";
export const CREATE_CAMPAING_FAIL = "CREATE_CAMPAING_FAIL";

export const GET_CARDS_SUCCESS = "GET_CARDS_SUCCESS";
export const GET_CARDS_FAIL = "GET_CARDS_FAIL";

export const SELECT_TEMPLATE_SUCCESS = "SELECT_TEMPLATE_SUCCESS";
export const SELECT_TEMPLATE_FAIL = "SELECT_TEMPLATE_FAIL";

export const SAVE_TEMPLATE_SUCCESS = "SAVE_TEMPLATE_SUCCESS";
export const SAVE_TEMPLATE_FAIL = "SAVE_TEMPLATE_FAIL";

export const NEW_OFFER = "NEW_OFFER";
export const OLD_OFFER = "OLD_OFFER";

export const RESET_STATE = "RESET_STATE";

import React, {useEffect} from 'react'
import Button from '@mui/material/Button';

import {useSelector} from "react-redux";
import Grid from "@mui/material/Grid";
import CampaingService from "../../../services/сampaings.service";
import DomainsService from "../../../services/domains.service";
import Alert from "@mui/material/Alert";

export default function FinalCheck() {

    const [isHttpsEnabled, setHttpsEnabled] = React.useState(false);

    const [isTestOrderCreated, setTestOrderCreated] = React.useState(false);

    const {
        domain,
        subdomain
    } = useSelector(state => state.domains);

    const handleOpenSite = (event) => {
        event.preventDefault();
        window.open("http://"+subdomain+"."+domain, "_blank");
    };

    const handleOpenFB = (event) => {
        event.preventDefault();
        window.open("https://docs.google.com/spreadsheets/d/1jK4GE_HQoK4i-gfsdMP370FBkWEnWTa5NHjBtl733mg/edit#gid=1465568892", "_blank");
    };

    const handleOpenSpent = (event) => {
        event.preventDefault();
        window.open("https://docs.google.com/spreadsheets/d/1aAbFj47u23UtjwmQa6NDWPnpbMzAgVGMOLy-ZLYqpJQ/edit#gid=799044453", "_blank");
    };

    const handleOpenCRM = (event) => {
        event.preventDefault();
        window.open("http://alina.lp-crm.biz", "_blank");
    };

    const handleCreateTestOrder = (event) => {
        event.preventDefault();
        setTestOrderCreated(true)
        CampaingService.createTestOrder(domain,"https://"+subdomain)
    };

    useEffect(() => {
        setTestOrderCreated(false)
        DomainsService.enableHttps(domain,subdomain).then((response)=>{
            setHttpsEnabled(true)
        })
    }, [])

    useEffect(() => {}, [isHttpsEnabled,isTestOrderCreated])

    const StatusInfo = () => {
        if(isTestOrderCreated) {
            return <Alert sx={{marginTop: 2, marginBottom: 3}} variant="filled" severity="warning">
                Тестове замовлення відправлено. Увага! Замовлення не надійде якщо https на сайті не працює!
            </Alert>
        }

        if(isHttpsEnabled) {
            return <Alert sx={{marginTop: 2, marginBottom: 3}} variant="filled" severity="warning">
                Https увімкненно. Зміни запрацюють протягом 10 хвилин. Перед завершенням компаннії перевірте роботу сайту та зробіть тестове замовлення.
            </Alert>
        }

        return <Alert sx={{marginTop: 2, marginBottom: 3}} variant="filled" severity="warning">
            Створення https ...
        </Alert>
    };

    return (
        <div>
            {StatusInfo()}
            <Grid container
                  spacing={2}
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  sx={{marginTop: 2,marginBottom: 5}}
            >
                <Grid item xs={5}>
                    <Button variant="contained"
                            onClick={handleOpenSite}
                            sx={{padding: '5px 5 5px'}}
                    > Перевірити сайт
                    </Button>
                </Grid>
                {/*<Grid item xs={5}>*/}
                {/*    <Button variant="contained"*/}
                {/*            onClick={handleOpenFB}*/}
                {/*            sx={{padding: '5px 5 5px'}}*/}
                {/*    > Перевірити таблицю аккаунти фб*/}
                {/*    </Button>*/}
                {/*</Grid>*/}
                {/*<Grid item xs={5}>*/}
                {/*    <Button variant="contained"*/}
                {/*            onClick={handleOpenSpent}*/}
                {/*            sx={{padding: '5px 5 5px'}}*/}
                {/*    > Перевірити таблицю витрачено*/}
                {/*    </Button>*/}
                {/*</Grid>*/}
                {/*<Grid item xs={5}>*/}
                {/*    <Button variant="contained"*/}
                {/*            onClick={handleOpenCRM}*/}
                {/*            sx={{padding: '5px 5 5px'}}*/}
                {/*    > Перевірити CRM*/}
                {/*    </Button>*/}
                {/*</Grid>*/}
                <Grid item xs={5}>
                    <Button variant="contained"
                            onClick={handleCreateTestOrder}
                            sx={{padding: '5px 5 5px'}}
                    > Надіслати тестове замовлення у CRM
                    </Button>
                </Grid>
            </Grid>
        </div>
    );
}
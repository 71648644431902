import {
    CREATE_SUBDOMAIN_SUCCESS,
    CREATE_SUBDOMAIN_FAIL,
    SET_MESSAGE,
} from "./types";

import DomainService from "../services/domains.service";

export const createSubDomain = (domain, subdomain) => (dispatch) => {
    return DomainService.createSubDomain(domain, subdomain).then(
        (response) => {

             const parsed_response = Object.values(response)[0];

            if(parsed_response === 'Субдомен створений'){
                    dispatch({
                        type: CREATE_SUBDOMAIN_SUCCESS,
                        payload: {domain:domain,subdomain:subdomain}
                    });
            }

             if(parsed_response ===' Cубдомен вже існує'){
                 dispatch({
                     type: CREATE_SUBDOMAIN_FAIL,
                 });
             }

            if(parsed_response === 'Неможливо створити сайт'){
                dispatch({
                    type: CREATE_SUBDOMAIN_FAIL,
                });
            }

            return Promise.resolve();
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            dispatch({
                type: CREATE_SUBDOMAIN_FAIL,
            });

            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });

            return Promise.reject();
        }
    );
};

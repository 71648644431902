import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import React, {useEffect, useState} from "react";
import cyrillicToTranslit from "cyrillic-to-translit-js";
import DomainService from "../../services/domains.service";
import DomainsService from "../../services/domains.service";
import {saveSiteTemplate} from "../../services/site.service";
import {useSnackbar} from "notistack";

export default function SaveCampaingMenu(props) {

    const [isSaved, setIsSaved] = useState(true);

    const [siteList, setSiteList] = React.useState([]);

    const saveButtonText = isSaved ? "Зберегти шаблон" : "Зачейкайте , йде збереження компанії..."

    const {enqueueSnackbar} = useSnackbar();

    useEffect(() => {
        loadDomains()
    }, []);

    const loadDomains = () => {
        DomainService.getSites()
            .then((response) => {
                setSiteList(new Map(Object.entries(response.data)))
            })
            .catch(() => {
            });
    }

    const closeMenu = (event) => {
        event.preventDefault()
        props.setOpenCampaingMenu(false)
    }

    const saveTemplate = (event) => {
        event.preventDefault();

        setIsSaved(false)
        let transliterated = cyrillicToTranslit({preset: "uk"}).transform(props.campaing.product.productCategory, "-").toLowerCase()
        let originSubDomain = transliterated
        let domain = 'rezerv.cyou'
        console.log(siteList)
        if (siteList.length !== 0 && siteList.has(domain)) {
            let subCount = 1
            let subdomainIsExit = siteList.get(domain).includes(originSubDomain + subCount) || siteList.get(domain).includes(originSubDomain + "-" + subCount)

            while (subdomainIsExit) {
                let countedSubdomainExit = siteList.get(domain).includes(originSubDomain + subCount) || siteList.get(domain).includes(originSubDomain + "-" + subCount)
                if (!countedSubdomainExit) subdomainIsExit = false
                else subCount++
            }

            const urltemplate = props.campaing.subdomain + `.` + props.campaing.domain
            //const sub = `${originSubDomain}-${subCount}`

            DomainService.createSubDomain(domain, originSubDomain + "-" + subCount).then(response => {
                const parsed_response = Object.values(response)[0];
                if (parsed_response === 'Субдомен створений') {
                    saveSiteTemplate(urltemplate, originSubDomain + "-" + subCount + '.' + "rezerv.cyou").then(
                        (response) => {
                            const parsed_response = Object.values(response)[0];
                            if (parsed_response.startsWith('Шаблон збережено')) {
                                DomainsService.enableHttps(domain, originSubDomain + "-" + subCount)
                                    .then(responseHttps => {
                                        if (responseHttps.data.success[0] === "Https увімкнено") {
                                            loadDomains()
                                            setIsSaved(true)
                                            props.loadTableData(0, 10)
                                            props.setOpenCampaingMenu(false)
                                            enqueueSnackbar('Шаблон збережено. Https запрацює протягом 10-15 хв.', {variant: "success"});
                                        }
                                    })
                            } else {
                                loadDomains()
                                setIsSaved(true)
                                enqueueSnackbar('Невдалося створити компанію. Спробуйте ще раз.', {variant: "error"});
                            }
                        },
                        (error) => {
                            loadDomains()
                            setIsSaved(true)
                            if (error.response.data.errors) {
                                enqueueSnackbar(error.response.data.errors[0], {variant: "error"});
                                return
                            }
                            enqueueSnackbar('Невідома помилка. Зверніться до адміністратора.', {variant: "error"});
                        })
                }
            })
        } else {
            enqueueSnackbar('Домен не знайдено.', {variant: "error"});
        }
    }

    return (
        <Modal
            open={props.open}
            onClose={closeMenu}
        >
            <Paper elevation={3}
                   sx={{
                       padding: 4,
                       position: 'absolute',
                       top: '50%',
                       left: '50%',
                       transform: 'translate(-50%, -50%)',
                       width: 800,
                       height: 400
                   }}>
                <Divider sx={{mb: 2}}><Typography id="modal-modal-title" variant="h6" component="h2" sx={{}}>
                    Збереження шаблону сайту
                </Typography></Divider>
                <Grid container
                      spacing={2}
                      direction="column"
                      justifyContent="center"
                      alignItems="center"
                      sx={{marginTop: 2, marginBottom: 5}}
                >
                    <Grid item xs={5}>
                        <Typography id="modal-modal-description" sx={{mb: 2}}>
                            Назва шаблону складатиметься з назви категорії товару
                        </Typography>
                        <Typography id="modal-modal-description" sx={{mb: 2}}>
                            Якщо такий шаблон вже існує до назви буде додано цифру
                        </Typography>
                    </Grid>
                    <Grid item xs={5}>

                        <Typography id="modal-modal-description" sx={{mb: 2}}>
                            {
                                cyrillicToTranslit({preset: "uk"})
                                    .transform(props.campaing.product.productCategory, "-")
                                    .toLowerCase()
                                + '.rezerv.cyou'
                            }
                        </Typography>
                    </Grid>
                    <Grid item xs={5}>
                        <Button variant="contained"
                                onClick={closeMenu}
                                sx={{padding: '5px 5 5px', marginBottom: 3, marginTop: 5, mr: 3}}
                                disabled={!isSaved}
                        > Скасувати
                        </Button>
                        <Button variant="contained"
                                onClick={saveTemplate}
                                sx={{padding: '5px 5 5px', marginBottom: 3, marginTop: 5}}
                                disabled={!isSaved || siteList.length === 0}
                        > {saveButtonText}
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
        </Modal>
    )
}
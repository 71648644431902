import React, {useEffect, useState} from 'react'
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CampaingService from "../../../services/сampaings.service";
import {CREATE_CAMPAING_FAIL, CREATE_CAMPAING_SUCCESS, SAVE_TEMPLATE_SUCCESS} from "../../../actions/types";
import Paper from "@mui/material/Paper";
import SheetsService from "../../../services/sheets.service";
import ReplayIcon from '@mui/icons-material/Replay';

import {TextField} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {saveSiteTemplate} from "../../../services/site.service";
import Alert from '@mui/material/Alert';

export default function CreateSite() {

    const {
        domain,
        subdomain
    } = useSelector(state => state.domains);

    const {
        template,
        source,
        medium,
        term,
        content,
        campaign,
        pixel,
        videoId,
        comment,
    } = useSelector(state => state.sites);

    const {
        SelectedProductId,
        SelectedProductCategory,
        SelectedProductPrice,
        SelectedProductCount,
    } = useSelector(state => state.products);

    const {
        card,
        accountName,
        budget
    } = useSelector(state => state.sheets);

    const {tempVideoId} = useSelector(state => state.sites);

    const {user: currentUser} = useSelector((state) => state.auth);

    const {isSiteCreated, isTemplateSaved, error, message} = useSelector(state => state.sites);

    const [templateTag, setTemplateTag] = useState("1");

    const [isSiteStartCreate, setSiteStartCreate] = useState(false);

    const [isStartSave, setStartSave] = useState(false);

    const dispatch = useDispatch();

    const [open, setOpen] = React.useState(false);

    const handleOpen = () => setOpen(true);

    const handleClose = () => setOpen(false);


    const payload = {
        "username": currentUser.username,
        "domain": domain,
        "subdomain": subdomain,
        "template": template,
        "comment": comment,
        "facebookPixelId": pixel,
        "youTubeVideoId": videoId === '' ? tempVideoId : videoId,
        "fb_account": {
            "accountName": accountName,
            "accountLogin": "",
            "accountPassword": "",
            "accountComment": "",
            "card": card
        },
        "initialBudget": budget,
        "product": {
            "productId": parseInt(SelectedProductId),
            "productCategory": SelectedProductCategory,
            "productPrice": parseInt(SelectedProductPrice),
            "productCount": parseInt(SelectedProductCount)
        },
        "utms": {
            "source": source,
            "medium": medium,
            "term": term,
            "content": content,
            "campaign": campaign
        }
    }

    const handleChangeTemplateTag = (event) => {
        event.preventDefault();
        setTemplateTag(event.target.value);
    };
    //
    // const createSiteCampaing = async () => {
    //     setSiteStartCreate(true)
    //     setTimeout(() => {
    //         if (!isSiteCreated) CampaingService.createCampaing(payload).then(
    //             (response) => {
    //                 if (response.data.success[0] === 'Компанію успішно створено') {
    //                     SheetsService.setCardData(accountName, subdomain + '.' + domain, SelectedProductCategory, budget, source, card)
    //                     setSiteStartCreate(false)
    //                     dispatch({
    //                         type: CREATE_CAMPAING_SUCCESS,
    //                     });
    //                 }else{
    //                     dispatch({
    //                         type: CREATE_CAMPAING_FAIL,
    //                         payload: {
    //                             message: 'Невдалося створити компанію. Спробуйте ще раз.'
    //                         }
    //                     });
    //                     setSiteStartCreate(false)
    //                 }
    //             }
    //         ).catch((error) => {
    //             dispatch({
    //                 type: CREATE_CAMPAING_FAIL,
    //                 payload: {
    //                     message: 'Невідома помилка. Повторіть ще раз чи зверніться до адміністратора.'
    //                 }
    //             });
    //             setSiteStartCreate(false)
    //         });
    //     }, 10000);
    // }

    const createSiteCampaing = async () => {
        let numAttempts = 0;
        while (numAttempts < 60) { // maximum of 3 attempts
            try {
                numAttempts++;
                setSiteStartCreate(true);
                await new Promise((resolve) => setTimeout(resolve, 10000));
                const response = await CampaingService.createCampaing(payload);
                if (response.data.success[0] === 'Компанію успішно створено') {
                    await SheetsService.setCardData(accountName, subdomain + '.' + domain, SelectedProductCategory, budget, source, card)
                    setSiteStartCreate(false);
                    dispatch({
                        type: CREATE_CAMPAING_SUCCESS,
                    });
                    return;
                } else {
                    console.log(`Невдалося створити компанію. Нова спроба ${numAttempts}`);
                }
            } catch (error) {
                if (numAttempts === 60) {
                    dispatch({
                        type: CREATE_CAMPAING_FAIL,
                        payload: {
                            message: 'Невідома помилка. Повторіть ще раз чи зверніться до адміністратора.',
                        },
                    });
                    setSiteStartCreate(false);
                    return;
                }
            }
        }
    };


    useEffect(() => {
        setStartSave(isStartSave)
        setSiteStartCreate(isSiteStartCreate)
        createSiteCampaing()
    }, []);

    useEffect(() => {
    }, [isStartSave, isSiteStartCreate]);

    const saveTemplate = async (event) => {
        setStartSave(true)
        event.preventDefault();
        await saveSiteTemplate(subdomain + "." + domain, SelectedProductCategory + "-" + templateTag).then((response) => {
            const parsed_response = Object.values(response)[0];
            if (parsed_response.startsWith('Шаблон збережено')) {
                dispatch({
                    type: SAVE_TEMPLATE_SUCCESS,
                });
            }
        })
        setStartSave(false);
    };

    const handleOpenEditor = (event) => {
        event.preventDefault();
        window.open("http://" + subdomain + "." + domain + "/editor", "_blank");
    };

    const handleRetryClick = (event) => {
        event.preventDefault();
        setStartSave(isStartSave)
        setSiteStartCreate(isSiteStartCreate)
        createSiteCampaing()
    };

    const StatusInfo = () => {
        if (isSiteCreated) {
            return <Alert sx={{marginTop: 2, marginBottom: 3}} variant="filled" severity="success">
                Сайт успішно створено , пароль редагування: YSadmin1211
            </Alert>
        }

        if (isSiteStartCreate) {
            return <Alert sx={{marginTop: 2, marginBottom: 3}} variant="filled" severity="warning">
                Йде завантаження сайту...
            </Alert>
        }

        if (error) {
            return <Alert sx={{marginTop: 2, marginBottom: 3}} variant="filled" severity="error">
                {message}
            </Alert>
        }
    };

    const StatusTemplateSave = () => {
        if (isTemplateSaved) {
            return <Alert sx={{marginBottom: 3}} variant="filled" severity="success">
                Шаблон успішно збережено
            </Alert>
        }
        if (error) {
            return <Alert sx={{marginBottom: 3}} variant="filled" severity="error">
                {message}
            </Alert>
        }

        if (isStartSave) return <Alert sx={{marginBottom: 3}} variant="filled" severity="warning">
            Збереження шаблону сайту...
        </Alert>
    };

    return (
        <div>
            {StatusInfo()}
            {error ?
                <div>
                    <ReplayIcon
                        onClick={handleRetryClick}
                        sx={{
                            ml: "40%",
                            mr: "50%",
                            fontSize: "72pt",
                            '&:hover': {
                                color: "#d32f2f",
                            }
                        }}
                    />
                    <Typography sx={{ml: "32%", mr: "30%", fontSize: "16pt"}}>Спробувати ще раз</Typography>
                </div> : ""}
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Paper elevation={3}
                       sx={{
                           padding: 4,
                           position: 'absolute',
                           top: '50%',
                           left: '50%',
                           transform: 'translate(-50%, -50%)',
                           width: 800,
                           height: 400
                       }}>
                    <Divider sx={{mb: 2}}><Typography id="modal-modal-title" variant="h6" component="h2" sx={{}}>
                        Збереження шаблону сайту
                    </Typography></Divider>
                    <Grid container
                          spacing={2}
                          direction="column"
                          justifyContent="center"
                          alignItems="center"
                          sx={{marginTop: 2, marginBottom: 5}}
                    >
                        <Grid item xs={5}>
                            {StatusTemplateSave()}
                            <Typography id="modal-modal-description" sx={{mb: 2}}>
                                Назва шаблону складатиметься з назви категорії товару та назви з поля що нижче
                            </Typography>
                        </Grid>
                        <Grid item xs={5}>
                            <TextField sx={{padding: '10px 0 5px', minWidth: 300}} id="standard-basic"
                                       disabled={true}
                                       defaultValue={SelectedProductCategory + "-"}
                                       variant="standard"
                                       onChange={handleChangeTemplateTag}
                            />
                            <TextField sx={{padding: '10px 0 5px', minWidth: 100}} id="standard-basic"
                                       defaultValue={1}
                                       variant="standard"
                                       onChange={handleChangeTemplateTag}
                            />
                        </Grid>
                        <Grid item xs={5}>
                            <Button variant="contained"
                                    onClick={saveTemplate}
                                    sx={{padding: '5px 5 5px', marginBottom: 3, marginTop: 5}}
                                    disabled={!isSiteCreated}
                            > Зберегти шаблон
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
            </Modal>
            <Grid container
                  spacing={2}
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  sx={{marginTop: 1, marginBottom: 5}}
            >
                <Grid item xs={5}>
                    <Button variant="contained"
                            onClick={handleOpenEditor}
                            sx={{padding: '5px 5 5px', marginBottom: 3, marginTop: 3, marginLeft: 1}}
                            disabled={!isSiteCreated}
                    > Редагувати cайт
                    </Button>
                    {/*<Button variant="contained"*/}
                    {/*        onClick={handleOpen}*/}
                    {/*        sx={{padding: '5px 5 5px', marginBottom: 3, marginTop: 3, marginLeft: 1}}*/}
                    {/*        disabled={!isSiteCreated}*/}
                    {/*> Зберегти шаблон*/}
                    {/*</Button>*/}
                </Grid>
            </Grid>
        </div>
    );
}
import {combineReducers} from "redux";
import auth from "./auth";
import message from "./message";
import domains from "./domains";
import products from "./products";
import sites from "./sites";
import sheets from "./sheets";
import offers from "./offers";

export default combineReducers({
    auth,
    message,
    domains,
    products,
    sites,
    sheets,
    offers,
});

import axios from "axios";
import authHeader from "./auth-header";

const LP_API_URL = process.env.REACT_APP_LP_API_URL
const CHEKBOX_API_URL = process.env.REACT_APP_CHEKBOX_API_URL

const getOrdersByStatus = (date,status) => {
    let data = {}
    return axios.get( LP_API_URL + `api/v1/check/getOrdersForCheck?dateStop=${date}&status=${status}`, data, {headers: ""});
};

const getOrdersByStatusPage = (status) => {
    return axios.get( LP_API_URL + `api/v2/lp-crm/getOrders?status=${status}`);
};

const openShifts = (id) => {
    var data = {}
    return axios.post(CHEKBOX_API_URL + `api/v1/check/openShifts?id=${id}`, data, {headers: authHeader()});
};

const closeShifts = (id) => {
    var data = {}
    return axios.post(CHEKBOX_API_URL + `api/v1/check/closeShifts?id=${id}`, data, {headers: authHeader()});
};

const createSell = async (id, sendSMS, order) => {
    return await axios.post(CHEKBOX_API_URL + `api/v1/check/sell?id=${id}&sms=${sendSMS}`, order, {headers: authHeader()});
};

const changeStatus = (id,status) => {
    return axios.post(LP_API_URL + `api/v1/changeOrderStatus?id=${id}&status=${status}`);
};

export default {
    getOrdersByStatus,
    openShifts,
    closeShifts,
    createSell,
    changeStatus,
    getOrdersByStatusPage,
};
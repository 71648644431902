import axios from "axios";
import authHeader from "./auth-header";

const CAMPAING_API_URL = process.env.REACT_APP_CAMPAING_API_URL

const getUserCampaings = (pagination) => {
    return axios.post(CAMPAING_API_URL , pagination, {headers: authHeader()});
};

const getAllCampaings = (pagination) => {
    return axios.post(CAMPAING_API_URL + 'all', pagination, {headers: authHeader()});
};

const createCampaing = (campaing) => {
    return axios.post(CAMPAING_API_URL + `create`, campaing, {headers: authHeader()});
};

const createTestOrder = (domain,subdomain) => {
    const payload = {
        "subdomain": subdomain,
        "domain": domain
    }
    return axios.post(CAMPAING_API_URL + `test/order`, payload, {headers: header()});
};

const saveCampaigChange = (payload) => {
    return axios.post(CAMPAING_API_URL + `selected/edit`, payload, {headers: header()});
};

const header = () => {
    const user = JSON.parse(localStorage.getItem("user"));

    if (user && user.token) {
        return {Authorization: "Bearer " + user.token,
            'Content-Type': 'application/json'
        };
    } else {
        return {};
    }
}

export default {
    getUserCampaings,
    createCampaing,
    createTestOrder,
    getAllCampaings,
    saveCampaigChange,
};
import React, {useEffect, useState} from 'react'
import {TextField} from "@mui/material";
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';

import DomainService from "../../../services/domains.service";

import {createSubDomain} from "../../../actions/domains";

import {useDispatch, useSelector} from "react-redux";
import Grid from "@mui/material/Grid";
import {isValidDomain} from "../../../helpers/Validators"
import cyrillicToTranslit from "cyrillic-to-translit-js";

export default function CreateSubDomain() {
    const {user: currentUser} = useSelector((state) => state.auth);
    let defaultDomain = ''
    if(currentUser){
        defaultDomain = currentUser.domains[0].toString()
    }
    const {SelectedProductCategory} = useSelector(state => state.products);
    let transliterated = cyrillicToTranslit({ preset: "uk" }).transform(SelectedProductCategory, "-").toLowerCase()

    const [subdomain, setSubDomain] = React.useState(transliterated);
    const [domain, setDomain] = useState(defaultDomain);
    const [siteList, setSiteList] = React.useState([]);
    const [starCreateSubDomain, setStarCreateSubDomain] = React.useState(false);

    const dispatch = useDispatch();

    const {isSubDomainCreated,error} = useSelector(state => state.domains);

    const loadDomains = () =>{
         DomainService.getSites()
            .then((response) => {
                setSiteList(new Map(Object.entries(response.data)))
            })
            .catch(() => {
            });
    }

    const DomainsSelect = (event) => {
        event.preventDefault();
        loadDomains()
    };

    const handleChangeDomain = (event, newValue) => {
        event.preventDefault();
        setDomain(newValue);
    };

    const handleChangeSubDomain = (event) => {
        event.preventDefault();
        setSubDomain(event.target.value);
    };

    const handleSubDomain = (e) => {
        e.preventDefault();

        console.log(siteList)

        if(siteList.has(domain)){
            console.log('has domain')
            let subdomainIsExit = siteList.get(domain).includes(subdomain)
            let subCount = 1

            while (!subdomainIsExit){
               let countedSubdomainExit = siteList.get(domain).includes(subdomain+"-"+subCount)
                console.log('countedSubdomainExit '+countedSubdomainExit)
                if(!countedSubdomainExit){
                    setSubDomain(subdomain+"-"+subCount)
                    setStarCreateSubDomain(true)
                    dispatch(createSubDomain(domain, subdomain+"-"+subCount))
                    subdomainIsExit = true
                }
                transliterated = subdomain + '-' + subCount
                subCount++
                console.log('subCount '+subCount)
            }
        }else{
            console.log("No domain")
        }
    }

    const StatusInfo = () => {
            if (isSubDomainCreated) {
                return <Alert sx={{marginTop:2,marginBottom: 3}} variant="filled" severity="success">
                    Субдомен успішно створено
                </Alert>
            }
            if(error){
                return <Alert sx={{marginTop:2,marginBottom: 3}} variant="filled" severity="error">
                    Невдалося створити субдомен. Субдомен вже існує чи відсутній зв'язок.
                </Alert>
            }
            if(starCreateSubDomain){
                return <Alert sx={{marginTop: 2, marginBottom: 3}} variant="filled" severity="warning">
                    Йде створення субдомену...
                </Alert>
            }
    };


    useEffect(() => {
       loadDomains()
    }, []);

    return (
        <div>
            {StatusInfo()}
            <Grid container
                  spacing={0}
                  justifyContent="center"
                  alignItems="center"
                  sx={{padding: 0,mt:3}}
            >
                <Grid item xs={6}>
                    <TextField sx={{padding: '5px 0 5px',minWidth: '300px'}}
                               disabled
                               label="Субдомен"
                               variant="standard"
                               value={subdomain}
                               defaultValue={transliterated}
                               error={!isValidDomain(subdomain)&&subdomain !== ""}
                               helperText={!isValidDomain(subdomain)&&subdomain !== ""?"Заборонені символи!":""}
                               onChange={handleChangeSubDomain}/>
                </Grid>
                <Grid item xs={5}>
                    <Autocomplete
                        disabled
                        loading
                        loadingText={'Завантаження...'}
                        disablePortal
                        id="combo-box-domains"
                        options={[...siteList.keys()]}
                        getOptionLabel={(option) => option.toString()}
                        defaultValue={defaultDomain}
                        onChange={handleChangeDomain}
                        onOpen={DomainsSelect}
                        renderInput={(params) => <TextField {...params} sx={{ padding: '5px 0 5px'}} variant="standard" label="Домен" />}
                    />
                </Grid>
                <Grid item xs={3}>
                    <Button variant="contained"
                            onClick={handleSubDomain}
                            sx={{padding: '5px 5 5px',marginTop: 3 , marginBottom: 3}}
                            disabled={isSubDomainCreated}
                    > Створити
                    </Button>
                </Grid>
            </Grid>
        </div>
    );
}
import React, {useEffect, useState} from 'react'

import {TextField} from "@mui/material";
import Alert from '@mui/material/Alert';
import {useDispatch, useSelector} from "react-redux";
import Autocomplete from "@mui/material/Autocomplete";
import {createSiteFromTemplate, getTemplateData, saveSiteTemplate} from "../../../services/site.service";

import {SELECT_TEMPLATE_FAIL, SELECT_TEMPLATE_SUCCESS} from "../../../actions/types";
import DomainService from "../../../services/domains.service";
import Grid from "@mui/material/Grid";
import ProductService from "../../../services/products.service";
import Button from "@mui/material/Button";

export default function SelectTemplate() {

    const [template, setTemplate] = useState([]);

    const [siteList, setSiteList] = useState([]);

    const [category, setCategory] = useState();

    const [way, setWay] = useState(['']);

    const [templateDomain, setTemplateDomain] = useState();

    const [templateSubDomain, setTemplateSubDomain] = useState();

    const {TemplateSelected, error} = useSelector(state => state.sites);

    const [isTemplateLoad, setIsTemplateLoad] = useState(false);

    const {domain, subdomain} = useSelector(state => state.domains);

    const [isProductExist, setIsProductExist] = useState(true);

    const [loadError, setLoadError] = useState();

    const dispatch = useDispatch();

    const loadSitesList = async (event) => {
        event.preventDefault();
        const options = []
       await DomainService.getSitesTemplates()
            .then((response) => {
                let domains = new Map(Object.entries(response.data));
                domains.forEach((subdomains, domain) => {
                    if(domain === 'rezerv.cyou') {
                        Object.values(subdomains).forEach(subdomain => {
                            let option = {
                                label: subdomain + "." + domain,
                                id: {
                                    subdomain: subdomain,
                                    domain: domain,
                                },
                            }
                            options.push(option)
                        })
                    }
                });
                setSiteList(options)
            })
            .catch(() => {
            });

        await ProductService.getProducts()
            .then((response) => {
                setCategory(response.data)
            })
    };

    useEffect(() => {
    }, [category]);

    const loadTemplate = async (event) => {
        event.preventDefault();
        setIsTemplateLoad(true)
        if (way === 'site') {
           // await dispatch(saveTemplate(templateSubDomain + "." + templateDomain, templateSubDomain + "." + templateDomain))
            await saveSiteTemplate(templateSubDomain + "." + templateDomain,templateSubDomain + "." + templateDomain).then(
                (response) => {
                    const parsed_response = Object.values(response)[0];

                    if(parsed_response.startsWith('Невдалося')){
                        dispatch({
                            type: SELECT_TEMPLATE_FAIL,
                        });
                    }

                    return Promise.resolve();
                });
        } else {
            await createSiteFromTemplate(domain,subdomain,template)
        }

        await getTemplateData(template).then((temp) => {
            if(category) {
                category.forEach(product => {
                    if (product.id === temp.data.productId) {
                        setIsProductExist(true)
                        dispatch({
                            type: SELECT_TEMPLATE_SUCCESS,
                            payload: {
                                template: template,
                                tempProductId: temp.data.productId,
                                tempProductPrice: temp.data.price,
                                tempProductCount: temp.data.count,
                                tempProductComment: temp.data.comment,
                                tempProductCategory: product.name.split(':')[0] + '',
                                tempVideoId: temp.data.videoId
                            }
                        });
                    } else {
                        setIsProductExist(false)
                    }
                })
            }else{
                setIsTemplateLoad(false);
                setLoadError(true)
            }
        });

        setIsTemplateLoad(false);
    };

    const handleChangeSite = (event, newvalue) => {
        event.preventDefault();
        setWay('site')
        setTemplate(newvalue.id.subdomain+'.'+newvalue.id.domain)
        setTemplateDomain(newvalue.id.domain)
        setTemplateSubDomain(newvalue.id.subdomain)
    }

    const handleOpenPreview = (event) => {
        event.preventDefault();
        if (way === 'site') window.open("http://" + template, "");
        else window.open("http://" + subdomain + '.' + domain, "");
    };

    const getPreviewActive = () =>{
        return way==='template' ? !TemplateSelected : isTemplateLoad;
    }

    const StatusInfo = () => {
        if (isTemplateLoad) {
            return <Alert sx={{marginTop: 2, marginBottom: 3}} variant="filled" severity="warning">
                Йде завантаження шаблону...
            </Alert>
        }

        if (TemplateSelected) {
            return <Alert sx={{marginTop: 2, marginBottom: 3}} variant="filled" severity="success">
                Шаблон успішно обрано
            </Alert>
        }
        if (error) {
            return <Alert sx={{marginTop: 2, marginBottom: 3}} variant="filled" severity="error">
                Невдалося встановити шаблон
            </Alert>
        }
        if (loadError) {
            return <Alert sx={{marginTop: 2, marginBottom: 3}} variant="filled" severity="error">
                Невдалося завантажити шаблон. Спробуйте ще раз.
            </Alert>
        }
        if (!isProductExist) {
            return <Alert sx={{marginTop: 2, marginBottom: 3}} variant="filled" severity="error">
                Невдалося знайти продукт з шаблону
            </Alert>
        }
    };

    return (
        <div>
            {StatusInfo()}
            <Grid container
                  spacing={2}
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
            >
                <Grid item xs={5}>
                    <Autocomplete
                        disablePortal
                        loading
                        loadingText={'Завантаження...'}
                        disabled={way === 'template'}
                        onOpen={loadSitesList}
                        onChange={handleChangeSite}
                        options={siteList}
                        groupBy={(option) => option.id.domain}
                        sx={{width: '50vh', marginBottom: 1}}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        renderInput={(params) => <TextField {...params} sx={{padding: '5px 0 5px'}} variant="standard"
                                                            label="Оберіть сайт"/>}
                    />
                </Grid>
                <Grid item xs={5}>
                    <Button variant="contained"
                            onClick={loadTemplate}
                            sx={{padding: '5px 5 5px', marginBottom: 3}}
                            disabled={category ? isTemplateLoad : true}
                    > Завантажити
                    </Button>
                    <Button variant="contained"
                            onClick={handleOpenPreview}
                            sx={{padding: '5px 5 5px', marginBottom: 3, marginLeft: 4}}
                            disabled={template.length === 0||getPreviewActive()}
                    > Переглянути
                    </Button>
                </Grid>
            </Grid>
        </div>
    );
}
import React, {useEffect, useState} from 'react'
import {TextField} from "@mui/material";
import {useDispatch} from "react-redux";
import Grid from "@mui/material/Grid";
import {CREATE_PIXEL} from "../../../actions/types";

export default function CreatePixel() {

    const [pixel, setPixel] = useState();

    const dispatch = useDispatch();

    useEffect(() => {
        createPixel()
    }, [pixel]);

    const createPixel = () => {
        dispatch({
            type: CREATE_PIXEL,
            payload: {
                pixel: pixel,
            }
        });
    }

    const handleChangePixel = (event) => {
        event.preventDefault();
        setPixel(event.target.value);
        createPixel()
    };

    return (
        <div>
            <Grid container
                  spacing={2}
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                      marginTop: 2,
                      marginBottom: 5
                  }}>
                <Grid item xs={5}>
                    <TextField id="standard-basic"
                               label="Pixel id"
                               variant="standard"
                               onChange={handleChangePixel}
                               sx={{
                                   padding: '10px 0 5px',
                                   marginRight: 3,
                                   minWidth: 300
                               }}/>
                </Grid>
            </Grid>
        </div>
    );
}
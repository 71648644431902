import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import React, {useState} from "react";
import Grid from "@mui/material/Grid";
import {TextField} from "@mui/material";

import MailsService from "../services/mails.service";

import LoadingButton from '@mui/lab/LoadingButton';
import ReplayIcon from '@mui/icons-material/Replay';
import {useSnackbar} from "notistack";

export default function BoardMails() {

    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);

    const {enqueueSnackbar} = useSnackbar();

    const genarateButtonText = !loading ? "Сгенерувати" : "Зачейкайте..."
    const createButtonText = !loading ? "Створити" : "Зачейкайте..."

    const handleLogin = (event) => {
        event.preventDefault();
        setLogin(event.target.value);
    };

    const handlePassword = (event) => {
        event.preventDefault();
        setPassword(event.target.value);
    };

    const genarateMail = (event) => {
        event.preventDefault();
        setLoading(true)
        MailsService.getGenaratedMail().then((response)=>{
            if (response.data.success[0] === 'Поштову скриньку створено') {
                setLogin(response.data.login)
                setPassword(response.data.password)
                enqueueSnackbar('Поштову скриньку створено', {variant: "success"});
            } else {
                enqueueSnackbar('Невдалося створити поштову скриньку. Спробуйте ще раз.', {variant: "error"});
            }
            setLoading(false)
        }).catch((error) => {
            enqueueSnackbar('Невдалося створити поштову скриньку. Спробуйте ще раз.', {variant: "error"});
            setLoading(false)
        })
    };

    const createMail = (event) => {
        event.preventDefault();
        const data = {
            "login": login,
            "password": password
        }
        setLoading(true)
        MailsService.createMail(data).then((response)=>{
            if (response.data.success[0] === 'Поштову скриньку створено') {
                enqueueSnackbar('Поштову скриньку створено', {variant: "success"});
            } else {
                enqueueSnackbar('Невдалося створити поштову скриньку. Спробуйте ще раз.', {variant: "error"});
            }
            setLoading(false)
        }).catch((error) => {
            enqueueSnackbar('Невдалося створити поштову скриньку. Спробуйте ще раз.', {variant: "error"});
            setLoading(false)
        })
    };

    return (
        <Box
            sx={{
                maxWidth: '60%',
                marginLeft: 'auto',
                marginRight: 'auto',
                padding: 4,
            }}
        >
            <Paper elevation={3} sx={{padding: 4}}>
                <Paper elevation={2} sx={{
                    marginBottom: 4,
                    color: 'white',
                    backgroundColor: '#1976d2',
                    padding: 1,
                    textShadow: '1px 0px 2px #000000'
                }}>
                    <center><h2>Поштові скриньки</h2></center>
                </Paper>

                <Grid container
                      spacing={2}
                      direction="column"
                      justifyContent="center"
                      alignItems="center"
                      sx={{marginBottom: 5}}
                >
                    <Grid item xs={5}>
                        <TextField sx={{padding: '10px 0 5px', minWidth: 400}}
                                   id="standard-basic"
                                   label="Логін"
                                   variant="standard"
                                   value={login}
                                   onChange={handleLogin}/>
                    </Grid>
                    <Grid item xs={5}>
                        <TextField sx={{padding: '10px 0 5px', minWidth: 400}}
                                   id="standard-basic"
                                   label="Пароль"
                                   variant="standard"
                                   value={password}
                                   onChange={handlePassword}/>
                    </Grid>
                    <Grid item xs={5}>
                        <LoadingButton
                            onClick={genarateMail}
                            endIcon={<ReplayIcon/>}
                            loading={loading}
                            loadingPosition="end"
                            variant="contained"
                            sx={{padding: '5px 5 5px', marginBottom: 3, marginTop: 3}}
                        >
                            {genarateButtonText}
                        </LoadingButton>
                        <LoadingButton
                            onClick={createMail}
                            endIcon={<ReplayIcon/>}
                            loading={loading}
                            loadingPosition="end"
                            variant="contained"
                            sx={{padding: '5px 5 5px', marginBottom: 3, marginTop: 3,marginLeft: 3}}
                        >
                            {createButtonText}
                        </LoadingButton>
                    </Grid>
                </Grid>
            </Paper>
        </Box>
    )
}
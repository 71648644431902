import axios from "axios";
import authHeader from "./auth-header";

const OPERATIONS_API_URL = process.env.REACT_APP_OPERATIONS_API_URL

const getUserOperations = (username,pageNumber,pageSize) => {
    return axios.get(OPERATIONS_API_URL + `getOperationsByUser?username=${username}&pageNumber=${pageNumber}&pageSize=${pageSize}`, {headers: authHeader()});
};

const getAllOperations = (pageNumber,pageSize) => {
    return axios.get(OPERATIONS_API_URL + `getOperations?pageNumber=${pageNumber}&pageSize=${pageSize}`, {headers: authHeader()});
};

const getOperationsByType = (type,pageNumber,pageSize) => {
    return axios.get(OPERATIONS_API_URL + `getOperationsByType?type=${type}&pageNumber=${pageNumber}&pageSize=${pageSize}`, {headers: authHeader()});
};

export default {
    getUserOperations,
    getAllOperations,
    getOperationsByType,
};
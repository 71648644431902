
export function isValidComment(comment) {
    let result;
    const re = /^[AaA-zA!:,-АаА-яАҐЄІЇґєіїёъ.!:,+()% 0-9\n]+$/;

    if (!comment||comment.includes("`")||comment.includes("//")) {
        result = false;
    } else {
        result = re.test(String(comment).toLowerCase());
    }

    return result;
}

export function isValidUtm(utm) {
    let result;
    const re = /^[-AaA-zA, 0-9]+$/;

    if (!utm||utm.includes("`")||utm.includes("//")) {
        result = false;
    } else {
        result = re.test(String(utm).toLowerCase());
    }

    return result;
}

export function isValidNumber(input) {
    let result;
    const re = /^[0-9]+$/;
    let text = input + "";

    if (!text||text.includes("`")||text.includes("//")) {
        result = false;
    } else {
        result = re.test(String(text).toLowerCase());
    }

    return result;
}

export function isValidDomain(text) {
    let result;
    const re = /^[a-z-0-9]+$/;

    if (!text||text.includes("`")||text.includes("//")) {
        result = false;
    } else {
        result = re.test(String(text).toLowerCase());
    }

    return result;
}

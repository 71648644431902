import axios from "axios";

const SHEETS_API_URL = process.env.REACT_APP_SHEETS_API_URL

const getCards  = () => {
    return axios.get(SHEETS_API_URL + "getCards");
};

const setCardData = (cab,siteurl,product,budget,utm,card) => {
    return axios.post(SHEETS_API_URL + `setCardData?cab=${cab}&siteurl=${siteurl}&product=${product}&budget=${budget}&utm=${utm}&card=${card}`);
};

export default {
    getCards,
    setCardData,
};

import React, { useEffect, useState } from 'react';
import { TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';
import { CREATE_COMMENT } from '../../../actions/types';
import { isValidComment } from '../../../helpers/Validators';

export default function CreateComment() {
    const { tempProductComment } = useSelector((state) => state.sites);
    const [comment, setComment] = useState(tempProductComment);
    const dispatch = useDispatch();

    useEffect(() => {
        createCOMMENT();
    }, [comment]);

    const createCOMMENT = () => {
        dispatch({
            type: CREATE_COMMENT,
            payload: {
                comment: comment,
            },
        });
    };

    const handleChangeComment = (event) => {
        event.preventDefault();
        const inputValue = event.target.value;
        const formattedComment = inputValue.split('\n').map(line => line.trimEnd() + '\n').join('');
        setComment(formattedComment);
    };

    return (
        <div>
            <Grid
                container
                spacing={2}
                direction="column"
                justifyContent="center"
                alignItems="center"
                sx={{ marginTop: 2, marginBottom: 5 }}
            >
                <Grid item xs={5}>
                    <TextField
                        name="comment"
                        id="outlined-multiline-static"
                        sx={{ padding: '10px 0 5px', marginRight: 3, minWidth: 400 }}
                        onChange={handleChangeComment}
                        defaultValue={tempProductComment}
                        multiline
                        rows={4}
                        required
                        error={!isValidComment(comment)}
                        helperText={!isValidComment(comment) ? "Заборонені символи у коментарі!" : ""}
                        label="Коментарій к товару"
                    />
                </Grid>
            </Grid>
        </div>
    );
}

import {
    GET_CARDS_SUCCESS,
    GET_CARDS_FAIL,
    CREATE_SHEETSDATA,
    RESET_STATE
} from "../actions/types";

const initialState = {};

export default function sheets(state = initialState, action) {
    const {type,payload} = action;

    switch (type) {
        case GET_CARDS_SUCCESS:
            return {
                ...state,
            };

        case GET_CARDS_FAIL:
            return {
                ...state,
               error: true
            };

        case CREATE_SHEETSDATA:
            return {
                ...state,
                card: payload.card,
                accountName: payload.accountName,
                budget: payload.budget
            };

        case RESET_STATE:
            return initialState;

        default:
            return state;
    }
}

import axios from "axios";
import authHeader from "./auth-header";

const API_CHECK_URL = process.env.REACT_APP_API_CHECK_URL

const getPublicContent = () => {
    return axios.get(API_CHECK_URL + "all");
};

const getUserBoard = () => {
    return axios.get(API_CHECK_URL + "user", {headers: authHeader()});
};

const getModeratorBoard = () => {
    return axios.get(API_CHECK_URL + "moderator", {headers: authHeader()});
};

const getAdminBoard = () => {
    return axios.get(API_CHECK_URL + "admin", {headers: authHeader()});
};

export default {
    getPublicContent,
    getUserBoard,
    getModeratorBoard,
    getAdminBoard,
};
import React, {useEffect} from 'react'
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import CreateSubDomain from "./Steps/CreateSubDomain";
import CreateProduct from "./Steps/CreateProduct";
import SelectTemplate from "./Steps/SelectTemplate";
import CreatePixel from "./Steps/CreatePixel";
import CreateUTMS from "./Steps/CreateUTMS";
import CreateComment from "./Steps/CreateComment";
import CreateSite from "./Steps/CreateSite";
import CreateCardsAndData from "./Steps/CreateCardsAndData";
import FinalCheck from "./Steps/FinalCheck";
import {useDispatch, useSelector} from "react-redux";
import {Navigate} from "react-router-dom";
import CreateVideo from "./Steps/CreateVideo";
import Chip from "@mui/material/Chip";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import {RESET_STATE} from "../../actions/types";
import {isValidComment,isValidUtm,isValidNumber} from "../../helpers/Validators"

const steps = [
    {
        label: 'Вибір шаблону сайту',
        description: 'Виберіть шаблон сайту зі збережених',
        view: <SelectTemplate/>,
    },
    {
        label: 'Додавання товару',
        description: 'Додайте товар, ціну та кількість до сайту',
        view: <CreateProduct isTest={true}/>,
    },
    {
        label: 'Створення субдомену',
        description: `Домен та субдомен буде встановлено автоматично`,
        view: <CreateSubDomain/>,
    },
    {
        label: 'Встановлення міток',
        description: 'Встановлення utms - міток рекламної компанії',
        view: <CreateUTMS/>,
    },
    {
        label: 'Встановлення коментарія',
        description: 'Встановлення коментарія до товару для менеджерів-операторів',
        view: <CreateComment/>,
    },
    {
        label: 'Встановлення пікселя',
        description: 'Встановлення пікселя-мітки рекламної компанії',
        view: <CreatePixel/>,
    },
    {
        label: 'Встановлення id відео',
        description: 'Додавання id відео до сайту',
        view: <CreateVideo/>,
    },
    {
        label: 'Встановлення карти',
        description: 'Додавання необхідних позначень у таблиці',
        view: <CreateCardsAndData/>,
    },
    {
        label: 'Створення сайту',
        description: 'Створення сайту за встановленими параметрами',
        view: <CreateSite/>,
    },
    {
        label: 'Перевірка',
        description: `Перевірте таблиці та данні`,
        view: <FinalCheck/>,
    },
];

const BoardTestCampaing = () => {
    const {
        comment,
        TemplateSelected,
        isSiteCreated,
        pixel,
        videoId,
        medium,
        campaign,
        error
    } = useSelector(state => state.sites);

    const {
        SelectedProductId,
        SelectedProductCategory,
        SelectedProductPrice,
        SelectedProductCount,
    } = useSelector(state => state.products);

    useEffect(() => {
        handleReset()
    }, []);

    const {card, accountName, budget} = useSelector(state => state.sheets);

    const {StatusCodeProduct} = useSelector(state => state.products);

    const {isSubDomainCreated} = useSelector(state => state.domains);

    const [activeStep, setActiveStep] = React.useState(0);

    const {user: currentUser} = useSelector((state) => state.auth);

    const dispatch = useDispatch();

    if (!currentUser) {
        return <Navigate to="/login"/>;
    }

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
        dispatch({
            type: RESET_STATE,
        });
    };

    const stepPassHandler = (step) => {
        switch (true) {
            case (step === 0 && !TemplateSelected):
                return true;
            case (step === 1):
                if(StatusCodeProduct === 3&&!isValidNumber(SelectedProductPrice)||!isValidNumber(SelectedProductCount)) return true;
                return false;
            case (step === 2 && !isSubDomainCreated):
                return true;
            case (step === 3):
                return !isValidUtm(campaign)||!isValidUtm(medium);
            case (step === 4):
                return !isValidComment(comment);
            case (step === 5 && pixel === undefined):
                return true;
            case (step === 6 && videoId === 'ignore'):
                return true;
            case ((step === 7 && accountName === '') || (step === 7 && budget === 0)):
                return true;
            case (step === 8 && !isSiteCreated):
                return true
            default:
                return false;
        }
    };

    const stepBackPassHandler = (step) => {
        switch (step) {
            case 0:
                return true
            case 1:
                return true;
            case 9:
                return !error;

            default:
                return false;
        }
    };

    return (
        <Box sx={{maxWidth: 800, marginLeft: 'auto', marginRight: 'auto', padding: 4}}>
            <Paper elevation={3} sx={{padding: 4}}>
                <Paper elevation={2} sx={{
                    marginBottom: 4,
                    color: 'white',
                    backgroundColor: '#1976d2',
                    padding: 1,
                    textShadow: '1px 0px 2px #000000'
                }}>
                    <center><h2>Створення тестової рекламної компанії</h2></center>
                </Paper>
                <Stepper activeStep={activeStep} orientation="vertical">
                    {steps.map((step, index) => (
                        <Step key={step.label}>
                            <StepLabel
                                optional={
                                    index === 10 ? (
                                        <Typography variant="caption">Завершення</Typography>
                                    ) : null
                                }
                            >
                                {step.label}
                            </StepLabel>
                            <StepContent>
                                <Divider><Chip label={step.description}/></Divider>
                                <Box sx={{mb: 2}}>
                                    {step.view}
                                    <Grid container
                                          direction="row"
                                          justifyContent="flex-end"
                                          alignItems="flex-end"
                                    >
                                        <Grid item>
                                            <Button
                                                disabled={stepBackPassHandler(index)}
                                                onClick={handleBack}
                                                sx={{mt: 1, mr: 2}}
                                            > Назад
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                variant="contained"
                                                onClick={handleNext}
                                                sx={{mt: 1, mr: 1}}
                                                disabled={stepPassHandler(index)}
                                            > {index === steps.length - 1 ? 'Завершити' : 'Далі'}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </StepContent>
                        </Step>
                    ))}
                </Stepper>
                {activeStep === steps.length && (
                    <Paper square elevation={0} sx={{p: 3}}>

                        <Divider><Chip label={'ВИ ВИКОНАЛИ УСІ КРОКИ'}/></Divider>
                        <Grid container
                              spacing={2}
                              direction="column"
                              justifyContent="center"
                              alignItems="center"
                              sx={{marginTop: 2, marginBottom: 3}}
                        >
                            <Grid item xs={5}>
                                <Button onClick={handleReset}
                                        sx={{mt: 1, mr: 1}}
                                        variant="contained"
                                        color="success"
                                > Почати з початку
                                </Button>
                            </Grid>
                        </Grid>
                    </Paper>
                )}
            </Paper>
        </Box>

    );
};

export default BoardTestCampaing;

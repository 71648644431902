import React, {useEffect, useState} from "react";

import Box from '@mui/material/Box';
import {useSelector} from "react-redux";
import Tabs, {tabsClasses} from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import "../../App.css";

import {DataGrid, GridColumns, ukUA} from '@mui/x-data-grid';
import {createTheme, darken, lighten, ThemeProvider} from '@mui/material/styles';

import Badge from '@mui/material/Badge';
import SettingsIcon from '@mui/icons-material/Settings';

import Popover from '@mui/material/Popover';

import Divider from '@mui/material/Divider';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import HttpsIcon from '@mui/icons-material/Https';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';

import {Navigate} from 'react-router-dom';

import CampaingsService from "../../services/сampaings.service";
import DomainsService from "../../services/domains.service";
import ReplayIcon from '@mui/icons-material/Replay';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import CompanyMenu from "./CompanyMenu";
import {useSnackbar} from "notistack";
import RestartCampaingMenu from "./RestartCampaingMenu";
import SaveCampaingMenu from "./SaveCampaingMenu";

const BoardCampaings = () => {

    const {enqueueSnackbar} = useSnackbar();

    const [campaing, setCampaing] = useState(false);

    const [pageCount, setPageCount] = useState(0);

    const [campaingsList, setCampaingsList] = useState([]);

    const {user: currentUser} = useSelector((state) => state.auth);

    const [tab, setTab] = useState(0);

    const [page, setPage] = useState(0);

    const [rowsPerPage, setRowsPerPage] = useState(25);

    const [isLoading, setIsLoading] = useState(true);

    const [showAdminBoard, setShowAdminBoard] = useState(false);

    const [openCampaingMenu, setOpenCampaingMenu] = useState(false);

    const [openRestartCampaingMenu, setOpenRestartCampaingMenu] = useState(false);

    const [openSaveCampaingMenu, setOpenSaveCampaingMenu] = useState(false);

    useEffect(() => {
        loadTableData(0, 25)
    }, []);


    const handleOpenCampaingMenu = (props) => {
        setCampaing(props.row)
        setOpenCampaingMenu(true);
    }

    const handleOpenSaveCampaingMenu = (event) => {
        event.preventDefault()
        const campaingList = campaingsList.filter(campaing => selectionModel.includes(campaing.id))
        if (campaingList.length !== 0) {
            const campaing = campaingList[0];
            setCampaing(campaing)
            setOpenSaveCampaingMenu(true);
        }else {
            enqueueSnackbar('Не вибрано жодної компанії', {variant: "warning"});
        }
    }

    const handleOpenRestartCampaingMenu = (event) => {
        event.preventDefault()
        const campaingList = campaingsList.filter(campaing => selectionModel.includes(campaing.id))
        if (campaingList.length !== 0) {
            const campaing = campaingList[0];
            setCampaing(campaing)
            setOpenRestartCampaingMenu(true);
        }else {
            enqueueSnackbar('Не вибрано жодної компанії', {variant: "warning"});
        }
    }

    const handlaChangePage = (newPage) => {
        setPage(newPage)
        //setCampaingsList([])
        setIsLoading(true)
        loadTableData(newPage, rowsPerPage, tab)
    }

    const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
        setTab(newValue);
        setPage(0);
        setRowsPerPage(rowsPerPage);
        setCampaingsList([])

        if (newValue === 0) loadTableData(page, rowsPerPage, newValue); else loadTableData(page, rowsPerPage, newValue)
    };

    const handleChangeRowsPerPage = (newRowPerPage) => {
        setRowsPerPage(newRowPerPage)
        setPage(0);
        setCampaingsList([])
        loadTableData(page, newRowPerPage, tab)
    };

    useEffect(() => {
        if (currentUser) {
            setShowAdminBoard(currentUser.roles.includes("ROLE_ADMIN"));
        } else {
            setShowAdminBoard(false);
        }

    }, [campaing, rowsPerPage, page, tab, campaingsList, isLoading,setIsLoading]);

    const handleCreateSSL = (event) => {
        event.preventDefault()
        const campaings = campaingsList.filter(campaing => selectionModel.includes(campaing.id));
        if(campaings.length !==0) {
            campaings.forEach(campaing => {
                DomainsService.enableHttps(campaing.domain, campaing.subdomain)
                    .then(response => {
                        if (response.data.success[0] == "Https увімкнено") {
                            enqueueSnackbar('Https увімкнено', {variant: "success"});
                        }
                    })

            })
        } else {
            enqueueSnackbar('Не вибрано жодної компанії', {variant: "warning"});
        }
    }

    const handleDelateCompany = (event) => {
        event.preventDefault()
        enqueueSnackbar('Не можна просто так взяти і видалити компанію', {variant: "info"});
    }

    const handleTransferCompany = (event) => {
        event.preventDefault()
        enqueueSnackbar('Ти просиш передати компанію, але робиш це без поваги...', {variant: "info"});
    }

    const loadTableData = (newPage, newRowsPerPage, currentTab) => {
        const options = []
        setIsLoading(true)

        const pagination = {
            "page": newPage,
            "size": newRowsPerPage
        }

        let campaings = CampaingsService.getUserCampaings(pagination);

        if (currentTab === 1) {
            campaings = CampaingsService.getAllCampaings(pagination);
        }

        campaings.then((response) => {
            Object.values(response.data.campaings).forEach(campaing => {
                    let option = {
                        id: campaing.id,
                        username: campaing.username,
                        domain: campaing.domain,
                        subdomain: campaing.subdomain,
                        template: campaing.template,
                        comment: campaing.comment,
                        facebookPixelId: campaing.facebookPixelId,
                        youTubeVideoId: campaing.youTubeVideoId === 'ignore' ? 'З шаблону' : campaing.youTubeVideoId,
                        date: campaing.timestamp.split('.')[0],
                        fb_account: {
                            accountName: campaing.fb_account.accountName,
                            accountLogin: campaing.fb_account.accountLogin,
                            accountPassword: campaing.fb_account.accountPassword,
                            accountComment: campaing.fb_account.accountComment,
                            card: campaing.fb_account.card
                        },
                        initialBudget: campaing.initialBudget,
                        product: {
                            id: campaing.product.id,
                            productId: campaing.product.productId,
                            productCategory: campaing.product.productCategory,
                            productPrice: campaing.product.productPrice,
                            productCount: campaing.product.productCount
                        },
                        utms: {
                            source: campaing.utms.source,
                            medium: campaing.utms.medium,
                            term: campaing.utms.term,
                            content: campaing.utms.content,
                            campaign: campaing.utms.campaign
                        }
                    }
                    options.push(option)
                }
            )
            setPageCount(response.data.size)
            setCampaingsList(options)
            setIsLoading(false)
        })
            .catch(() => {
            });
    }

    const columns: GridColumns = [
        {
            field: 'id',
            headerName: "ID",
            headerAlign: 'center',
            align: 'center',
            maxWidth: 80,
            flex: 1
        },
        {
            field: 'accountName',
            valueGetter: (params) => {
                return params.row.fb_account.accountName
            },
            headerName: "Аккаунт",
            minWidth: 140,
            headerAlign: 'center',
            align: 'center',
            flex: 2
        },
        {
            field: 'productCategory',
            valueGetter: (params) => {
                return params.row.product.productCategory
            },
            headerName: "Товар",
            minWidth: 80,
            maxWidth: 350,
            headerAlign: 'center',
            align: 'center',
            flex: 3
        },
        {
            field: 'price',
            valueGetter: (params) => {
                return params.row.product.productPrice
            },
            headerName: "Ціна",
            headerAlign: 'center',
            align: 'center',
            maxWidth: 80,
            flex: 4
        },
        {
            field: 'siteUrl',
            valueGetter: (params) => {
                return params.row.subdomain + '.' + params.row.domain
            },
            headerName: "Сайт",
            headerAlign: 'center',
            align: 'center',
            minWidth: 40,
            maxWidth: 350,
            flex: 5
        },
        {
            field: 'initialBudget',
            headerName: "Бюджет",
            headerAlign: 'center',
            align: 'center',
            maxWidth: 80,
            flex: 6
        },
        {
            field: 'date',
            headerName: "Дата",
            headerAlign: 'center',
            align: 'center',
            maxWidth: 160,
            flex: 7
        }
    ];

    const [selectionModel, setSelectionModel] = React.useState([]);

    const myTheme = createTheme({
        components: {
            MuiDataGrid: {
                styleOverrides: {
                    row: {
                        "&.Mui-selected": {
                            backgroundColor: "rgba(248,228,6,0.5)",
                            "&:hover": {
                                backgroundColor: "rgba(248,228,6,1)",
                            }
                        }
                    }
                }
            }
        }
    });

    const getHoverBackgroundColor = (color: string, mode: string) =>
        mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    if (!currentUser) return <Navigate to="/login"/>;

    return (
        <ThemeProvider theme={myTheme}>
            <Box
                sx={{
                    maxWidth: '80%',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    padding: 4,
                    wordWrap: 'break-word',
                    "& .MuiDataGrid-virtualScrollerRenderZone": {
                        "& .MuiDataGrid-row": {
                            "&:nth-of-type(1n):not(.Mui-selected)": {backgroundColor: "rgba(255,255,255, .5)"},
                            "&:nth-of-type(2n):not(.Mui-selected)": {backgroundColor: "rgba(224, 224, 224, .5)"},
                            '&:hover:not(.Mui-selected)': {
                                bgcolor: (theme) =>
                                    getHoverBackgroundColor("rgba(101,99,99,0.5)", theme.palette.mode),
                            },
                        },
                    },
                    "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: "rgba(52,58,64,1)",
                        color: "rgba(255,255,255,1)",
                        fontSize: 16
                    },
                    "& .MuiDataGrid-footerContainer": {
                        backgroundColor: "rgba(52,58,64,1)",
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        "& .MuiTablePagination-selectLabel": {
                            paddingTop: 1.4
                        },
                        "& .MuiTablePagination-displayedRows": {
                            paddingTop: 1.4
                        },
                        "& .MuiDataGrid-selectedRowCount": {
                            color: 'white',
                        }
                    },
                    "& .MuiTablePagination-toolbar": {
                        color: 'white',
                    },
                }}
            >
                <Box
                    sx={{
                        flexGrow: 1,
                        display: "flex",
                        justifyContent: "center",
                        width: "100%",
                    }}
                >
                    <Tabs
                        value={tab}
                        onChange={handleChangeTab}
                        variant="scrollable"
                        scrollButtons
                        aria-label="visible arrows tabs example"
                        sx={{
                            [`& .${tabsClasses.scrollButtons}`]: {
                                '&.Mui-disabled': {opacity: 0.3},
                            },
                            marginLeft: 'auto',
                            marginRight: 'auto'
                        }}
                    >
                        <Tab label="Мої компанії"/>
                        {showAdminBoard && (<Tab label="Усі компанії"/>)}
                    </Tabs>
                    <Badge badgeContent={selectionModel.length}
                           color="primary"
                           onClick={handleClick}
                    >
                        <SettingsIcon sx={{"&:hover": {color: "blue"}}} fontSize="large" color="action"/>
                    </Badge>

                    <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                    >
                        <MenuList>
                            <MenuItem onClick={handleCreateSSL}>
                                <ListItemIcon>
                                    <HttpsIcon fontSize="small"/>
                                </ListItemIcon>
                                <ListItemText>Увімкнути HTTPS</ListItemText>
                            </MenuItem>
                            <Divider/>
                            <MenuItem onClick={handleOpenRestartCampaingMenu}>
                                <ListItemIcon>
                                    <ReplayIcon fontSize="small"/>
                                </ListItemIcon>
                                <ListItemText>Перезапустити компанію</ListItemText>
                            </MenuItem>
                            <Divider/>
                            <MenuItem onClick={handleOpenSaveCampaingMenu}>
                                <ListItemIcon>
                                    <SaveIcon fontSize="small"/>
                                </ListItemIcon>
                                <ListItemText>Зберегти компанію у шаблон</ListItemText>
                            </MenuItem>
                            <Divider/>
                            <MenuItem onClick={handleTransferCompany}>
                                <ListItemIcon>
                                    <CompareArrowsIcon fontSize="small"/>
                                </ListItemIcon>
                                <ListItemText>Передати компанію</ListItemText>
                            </MenuItem>
                            <Divider/>
                            <MenuItem onClick={handleDelateCompany}>
                                <ListItemIcon>
                                    <DeleteIcon fontSize="small"/>
                                </ListItemIcon>
                                <ListItemText>Видалити компанію</ListItemText>
                            </MenuItem>
                        </MenuList>
                    </Popover>
                </Box>

                <div style={{width: '100%', backgroundColor: 'white'}}>
                    <div>
                        {(campaing.id ?
                            <CompanyMenu
                                open={openCampaingMenu}
                                campaing={campaing}
                                loadTableData={loadTableData}
                                setOpenCampaingMenu={setOpenCampaingMenu}
                            /> : <div></div>)}

                        {(campaing.id ?
                            <RestartCampaingMenu
                                open={openRestartCampaingMenu}
                                campaing={campaing}
                                loadTableData={loadTableData}
                                setOpenCampaingMenu={setOpenRestartCampaingMenu}
                            /> : <div></div>)}

                        {(campaing.id ?
                            <SaveCampaingMenu
                                open={openSaveCampaingMenu}
                                campaing={campaing}
                                loadTableData={loadTableData}
                                setOpenCampaingMenu={setOpenSaveCampaingMenu}
                            /> : <div></div>)}


                        <DataGrid
                            checkboxSelection={true}
                            loading={isLoading}
                            rows={campaingsList}
                            columns={columns}
                            rowCount={pageCount}
                            rowsPerPageOptions={[5, 10, 25, 50, 100]}
                            page={page}
                            onPageChange={handlaChangePage}
                            onCellDoubleClick={handleOpenCampaingMenu}
                            pageSize={rowsPerPage}
                            onPageSizeChange={handleChangeRowsPerPage}
                            autoHeight={true}
                            showCellRightBorder={true}
                            rowHeight={25}
                            localeText={ukUA.components.MuiDataGrid.defaultProps.localeText}
                            onSelectionModelChange={(newSelectionModel) => {
                                setSelectionModel(newSelectionModel);
                            }}
                            selectionModel={selectionModel}
                            pagination
                            paginationMode="server"
                        />
                    </div>
                </div>
            </Box>
        </ThemeProvider>
    );
};

export default BoardCampaings;
import {Provider} from "react-redux";
import store from "./store";
import * as serviceWorker from "./serviceWorker";
import React from "react";
import "./index.css";
import App from "./App";
import {SnackbarProvider} from "notistack";
import { createRoot } from "react-dom/client";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
        <SnackbarProvider anchorOrigin={{ horizontal:'top', vertical:'right' }}>
            <Provider store={store}>
                <App/>
            </Provider>
        </SnackbarProvider>,
);

serviceWorker.unregister();

import Typography from "@mui/material/Typography";
import React from "react";

export default function CardText(props){
   return(
       <div style={{display:"flex", flexDirection:"row"}}>
            <Typography sx={{color:props.color,mr:1}}>- {props.subtext}</Typography>
            <Typography>{props.text}</Typography>
       </div>
   )
}
import {CREATE_SUBDOMAIN_SUCCESS, CREATE_SUBDOMAIN_FAIL, RESET_STATE} from "../actions/types";

const initialState = {isSubDomainCreated:false};

export default function domains(state = initialState, action) {
    const {type,payload} = action;

    switch (type) {
        case CREATE_SUBDOMAIN_SUCCESS:
            return {
            ...state,
                isSubDomainCreated: true,
                domain: payload.domain,
                subdomain: payload.subdomain,
        };

        case CREATE_SUBDOMAIN_FAIL:
            return {
                ...state,
                error: true,
            };

        case RESET_STATE:
            return initialState;

        default:
            return state;
    }
}

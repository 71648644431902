import React, {useEffect, useState} from "react";

import Box from '@mui/material/Box';
import {useSelector} from "react-redux";
import Tabs, {tabsClasses} from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import "../App.css";
import OperationsService from "../services/operations.service";

import {DataGrid, GridColumns, ukUA} from '@mui/x-data-grid';
import {darken, lighten, ThemeProvider, createTheme} from '@mui/material/styles';

function getDateFromTimeStamp(timestamp) {
    let date = new Date(timestamp);

    let day = String(date.getDate()).padStart(2, '0')
    let month = String(date.getMonth() + 1).padStart(2, '0')
    let year = date.getFullYear();

    let hours = date.getHours();
    let minutes = "0" + date.getMinutes();
    let seconds = "0" + date.getSeconds();

    return day + '.' + month + '.' + year + ' ' + hours + ':' + minutes.slice(-2) + ':' + seconds.slice(-2);
}

const rows = [
    {id: 1, username: "", content: "loading", timestamp: "", type: ""},
];

const BoardUser = () => {
    const [pageCount, setPageCount] = React.useState(1);

    const [operationsList, setOperationsList] = useState(rows);

    const {user: currentUser} = useSelector((state) => state.auth);

    const [tab, setTab] = React.useState(0);

    const [page, setPage] = React.useState(0);

    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    if (!currentUser) {
        //return <Navigate to="/login"/>;
    }

    const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
        setTab(newValue);
        setPage(0);
    };

    const handleChangeRowsPerPage = (newPage) => {
        setRowsPerPage(newPage)
        setPage(0);
    };

    useEffect(() => {
        setPage(page);
        setRowsPerPage(rowsPerPage);
        setTab(tab);

        switch (tab) {
            case 0: {
                loadUserOperations(rowsPerPage);
                break;
            }
            case 1: {
                loadCreateSiteOperations(rowsPerPage)
                break;
            }
            case 2: {
                loadAllOperations(rowsPerPage);
                break;
            }
            default:
                loadUserOperations(rowsPerPage);
        }

    }, [rowsPerPage, page, tab]);

    const columns: GridColumns = [
        {
            field: 'id',
            headerName: "ID",
            headerAlign: 'center',
            align: 'center',
            maxWidth: 50,
            flex: 1
        },
        {
            field: 'username',
            headerName: "Користувач",
            maxWidth: 200,
            headerAlign: 'center',
            align: 'center',
            flex: 2
        },
        {
            field: 'content',
            headerName: "Дані",
            headerAlign: 'center',
            align: 'center',
            minWidth: 240,
            flex: 3
        },
        {
            field: 'timestamp',
            headerName: "Дата",
            headerAlign: 'center',
            align: 'center',
            maxWidth: 200,
            flex: 4
        },
        {
            field: 'type',
            headerName: "Тип операції",
            headerAlign: 'center',
            align: 'center',
            maxWidth: 200,
            flex: 5
        }
    ];

    const [checkboxSelection, setCheckboxSelection] = React.useState(true);

    const loadAllOperations = (rowsperpage) => {
        const options = []
        OperationsService.getAllOperations(page, rowsperpage)
            .then((response) => {
                loadTableData(response, options)
            })
            .catch(() => {
            });
    };

    const loadCreateSiteOperations = (rowsperpage) => {
        const options = []
        OperationsService.getOperationsByType(6, page, rowsperpage)
            .then((response) => {
                loadTableData(response, options)
            })
            .catch(() => {
            });
    };

    const loadUserOperations = (rowsperpage) => {
        const options = []
        OperationsService.getUserOperations(currentUser.username, page, rowsperpage)
            .then((response) => {
                loadTableData(response, options)
            })
            .catch(() => {
            });
    };

    const loadTableData = (response, options) => {
        Object.values(response.data.Operations).forEach(operation => {
                let date = getDateFromTimeStamp(operation.timestamp)
                options.push({
                    id: operation.id,
                    username: operation.username,
                    content: operation.content,
                    timestamp: date,
                    type: operation.type
                })
            }
        )
        setPageCount(response.data.TotalOperations)
        setOperationsList(options)
    }

    const myTheme = createTheme({
        components: {
            MuiDataGrid: {
                styleOverrides: {
                    row: {
                        "&.Mui-selected": {
                            backgroundColor: "rgba(248,228,6,0.5)",
                            "&:hover": {
                                backgroundColor: "rgba(248,228,6,1)",
                            }
                        }
                    }
                }
            }
        }
    });

    const getHoverBackgroundColor = (color: string, mode: string) =>
        mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);

    return (
        <ThemeProvider theme={myTheme}>
            <Box
                sx={{
                    maxWidth: '70%',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    padding: 4,
                    "& .MuiDataGrid-virtualScrollerRenderZone": {
                        "& .MuiDataGrid-row": {
                            "&:nth-of-type(1n):not(.Mui-selected)": {backgroundColor: "rgba(255,255,255, .5)"},
                            "&:nth-of-type(2n):not(.Mui-selected)": {backgroundColor: "rgba(224, 224, 224, .5)"},
                            '&:hover:not(.Mui-selected)': {
                                bgcolor: (theme) =>
                                    getHoverBackgroundColor("rgba(101,99,99,0.5)", theme.palette.mode),
                            },
                        },
                    },
                    "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: "rgba(52,58,64,1)",
                        color: "rgba(255,255,255,1)",
                        fontSize: 16
                    },
                    "& .MuiDataGrid-footerContainer": {
                        backgroundColor: "rgba(52,58,64,1)",
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        "& .MuiTablePagination-selectLabel": {
                            paddingTop: 1.4
                        },
                        "& .MuiTablePagination-displayedRows": {
                            paddingTop: 1.4
                        },
                        "& .MuiDataGrid-selectedRowCount": {
                            color: 'white',
                        }
                    },
                    "& .MuiTablePagination-toolbar": {
                        color: 'white',
                    },
                }}
            >
                <Box
                    sx={{
                        flexGrow: 1,
                        display: "flex",
                        justifyContent: "center",
                        width: "100%",
                    }}
                >
                    <Tabs
                        value={tab}
                        onChange={handleChangeTab}
                        variant="scrollable"
                        scrollButtons
                        aria-label="visible arrows tabs example"
                        sx={{
                            [`& .${tabsClasses.scrollButtons}`]: {
                                '&.Mui-disabled': {opacity: 0.3},
                            },
                        }}
                    >
                        <Tab label="Мої операції"/>
                        <Tab label="Створення сайту"/>
                        <Tab label="Усі операції"/>
                    </Tabs>
                </Box>

                <div style={{width: '100%', backgroundColor: 'white'}}>
                    <div>
                        <DataGrid checkboxSelection={checkboxSelection}
                                  rows={operationsList}
                                  columns={columns}
                                  rowCount={pageCount}
                                  rowsPerPageOptions={[5, 10, 25, 50, 100]}
                                  page={page}
                                  onPageChange={(newPage) => setPage(newPage)}
                                  rowsPerPage={rowsPerPage}
                                  pageSize={rowsPerPage}
                                  onPageSizeChange={handleChangeRowsPerPage}
                                  autoHeight={true}
                                  showCellRightBorder={true}
                                  pagination
                                  paginationMode={"server"}
                                  localeText={ukUA.components.MuiDataGrid.defaultProps.localeText}
                        />
                    </div>
                </div>
            </Box>
        </ThemeProvider>
    );
};

export default BoardUser;

import React, {useEffect,useState} from 'react'
import {TextField} from "@mui/material";

import {useDispatch} from "react-redux";

import Autocomplete from "@mui/material/Autocomplete";
import SheetsService from "../../../services/sheets.service";
import Grid from "@mui/material/Grid";
import {CREATE_SHEETSDATA} from "../../../actions/types";

export default function CreateCardsAndData() {

    // const [card, setCard] = useState();  Отключено до обновления

    const [accountName, setAccountName] = useState('');

    const [budget, setBudget] = useState(0);
    // Отключено до обновления
    // const [cardList, setCardList] = useState([]);

    const dispatch = useDispatch();

    useEffect(() => {
        createSHEETDATA()
    }, [accountName,budget]);

    const createSHEETDATA = () => {
        dispatch({
            type: CREATE_SHEETSDATA,
            payload: {
                card: "",
                accountName: accountName,
                budget: budget
            }
        });
    }
    // Отключено до обновления
    // const loadCardList = (event) => {
    //     event.preventDefault();
    //     const options = []
    //     SheetsService.getCards()
    //         .then((response) => {
    //             Object.values(response.data).forEach(card => {
    //                     let option = {
    //                         label: card,
    //                         id: card,
    //                     }
    //                     options.push(option)
    //                 }
    //             )
    //             setCardList(options)
    //         })
    //         .catch(() => {
    //         });
    // };

    const handleChangeAcountName = (event) => {
        event.preventDefault()
        setAccountName(event.target.value)
        createSHEETDATA()
    };

    const handleChangeBudget= (event) => {
        event.preventDefault()
        setBudget(event.target.value)
        createSHEETDATA()
    };
    // Отключено до обновления
    // const handleChangeCard= (event,newvalue) => {
    //     event.preventDefault()
    //     setCard(newvalue.id)
    //     createSHEETDATA()
    // };

    return (
        <div>
            <Grid container
                  spacing={2}
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  sx={{marginTop:2,marginBottom: 5}}
            >
                <Grid item xs={5}>
                    <TextField sx={{padding: '10px 0 5px', minWidth: 300}}
                               id="standard-basic"
                               label="Назва аккаунту facebook"
                               variant="standard"
                               onChange={handleChangeAcountName}/>
                </Grid>
                <Grid item xs={5}>
                    <TextField sx={{padding: '10px 0 5px', minWidth: 300}}
                               id="standard-basic"
                               label="Бюджет"
                               variant="standard"
                               onChange={handleChangeBudget}/>

                </Grid>
                <Grid item xs={5}>
                    {/*Отключено до обновления*/}
                    {/*<Autocomplete*/}
                    {/*    loading*/}
                    {/*    loadingText={'Завантаження...'}*/}
                    {/*    disablePortal*/}
                    {/*    id="combo-box-cards"*/}
                    {/*    onOpen={loadCardList}*/}
                    {/*    options={cardList}*/}
                    {/*    sx={{width: 300}}*/}
                    {/*    onChange={handleChangeCard}*/}
                    {/*    renderInput={(params) => <TextField {...params} sx={{padding: '5px 0 5px'}} variant="standard"*/}
                    {/*                                        label="Оберіть карту"/>}*/}
                    {/*/>*/}
                </Grid>
            </Grid>
        </div>
    );
}
import React, {useState, useEffect} from "react";

import UserService from "../services/user.service";
import EventBus from "../common/EventBus";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import {useSelector} from "react-redux";
import {Navigate} from "react-router-dom";

const BoardModerator = () => {
    const [content, setContent] = useState("");

    useEffect(() => {
        UserService.getModeratorBoard().then(
            (response) => {
                setContent(response.data);
            },
            (error) => {
                const _content =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                setContent(_content);

                if (error.response && error.response.status === 401) {
                    EventBus.dispatch("logout");
                }
            }
        );
    }, []);

    const {user: currentUser} = useSelector((state) => state.auth);

    if (!currentUser) {
        return <Navigate to="/login"/>;
    }

    return (
        <Box sx={{maxWidth: 600, marginLeft: 'auto', marginRight: 'auto', padding: 4}}>
            <Paper elevation={3} sx={{padding: 4}}>
                <h3>{content}</h3>
            </Paper>
        </Box>
    );
};

export default BoardModerator;
